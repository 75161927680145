@import '../vars/colors.scss';

.accordion {
    border-radius: 5px !important;
    border: 1px solid #e4e4e4;
    box-shadow: none !important;
    margin-bottom: 1rem;
    p {
        color: $erste-darkblue;
    }


    .css-1elwnq4-MuiPaper-root-MuiAccordion-root:before {
        content: unset !important;
    }


    .MuiAccordionSummary-content {
        color: $erste-darkblue;
        margin: 15px 0 !important;

        h2, h3, h4 {
        margin: 0;
        font-weight: 600;
        font-size: 1.3rem;
        }
    }

    .MuiAccordionSummary-root.Mui-expanded {
        min-height: 56px;
    }

    .MuiAccordionSummary-expandIconWrapper {
        svg {
        height: auto;
        width: 20px;
        }
    }

    .MuiAccordionDetails-root {
        padding:0;
    }

    &.accordion-faq {
        border-left: none;
        border-radius: 0px !important;
        border-right: none;
        border-color: #e9eaf0;
        margin: 0 !important;
        border-top: 0;
        .MuiAccordionSummary-content {
            margin: 18px 0 !important;
        }
        .Mui-expanded {
            h2 {
                color: $erste-blue;
            }
            .css-yw020d-MuiAccordionSummary-expandIconWrapper.Mui-expanded {
                transform: rotate(45deg);
            }
        }
        .MuiAccordionDetails-root {
            padding-bottom: 16px;
        }
        .MuiAccordionSummary-root {
            padding: 0;
        }
        .css-1elwnq4-MuiPaper-root-MuiAccordion-root.Mui-expanded {
            margin: 0 !important;
        }
        .MuiCollapse-vertical.MuiCollapse-entered {
            border-top: 0;
        }
        p {
            line-height: 1.5;
        }

        h2 {
            margin-right: 1rem;
        }
        .MuiAccordionSummary-expandIconWrapper {
            svg {
            width: 24px;
            }
        }
    }
}

.css-1elwnq4-MuiPaper-root-MuiAccordion-root:first-of-type {
    border-top: 1px solid #e9eaf0;
}


.faq {
    .css-1elwnq4-MuiPaper-root-MuiAccordion-root:before {
        height: 0px;
    }
}

