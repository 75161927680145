.printview {
    display: none !important;
}
  
@media print {
	@page {
		size: A4 portrait;
		margin: 10mm;
	}

	.printview {
		display: block !important;
	}

	.print--spacer {
		display: inline-block;
		width: 100%;
		height: 40px;
		
	}

	#header, footer, .main-content > *:not(.printview) {
		display: none !important;
	}

	#immodetailprint {
		margin-top: 2rem;

		.immodetail__headline {
			width: 70%;
			float: left;

			h1 {
				display: block;
				padding-bottom: 20px;
			}

			ul {
				padding-left: 30px;
				list-style-type: disc;
			}
		}

		.immodetail__cover {
			width: 29%;
			max-width: 29%;
			float: left;
		}

		.immodetail__features {
			width: 100%;
			display: inline-block;
			padding-top: 40px;
			padding-bottom: 40px;

			.immodetail__features-wrapper {
				width: 33%;
				float: left;
			}
		}

		.immodetail__table-wrapper {
			width: 100%;
			.immodetail__table:first-child {
				width: 48%;
				float: left;
			}
			.immodetail__table:nth-child(2) {
				width: 48%;
				float: right;
			}
		}

		.contactagent-card {
			width: 50%;
		}

		.immodetail__desc {
			page-break-before: always;
		}

		strong {
			font-weight: 700;
		}
	}
}