.scale-1-7 {
    scale: 1.7;
}

.scale-0-5{
    transform: scale(0.5);
}

.scale-1-5 {
    transform: scale(1.5) !important;
}

.scale-2 {
    scale: 2;
}

.scale-2-3 {
    scale: 2.3;
}

.scale-2-6 {
    scale: 2.6;
}