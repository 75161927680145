@import '../../../../shared/styles/sreal_default/vars/colors.scss';
@import '../../../../shared/styles/sreal_default/vars/breaks.scss';

$arrow_width: 50px;

.media-slider {
	position: relative;

  .oneImage-container {
    height: 300px;
    display: flex;
    justify-content: center;
    img {
      object-fit: cover;
      height: 100%;
    }
  }

	.media-slider__label {
		display: flex;
		align-items: center;
		background-color: $erste-blue;
		color: #fff;
		width: fit-content;
		height: fit-content;
		border-radius: 5px;
		position: absolute;
		top: 0.5rem;
		left: calc((100vw - 1170px) / 2 + 0.5rem);
		padding: 0.5rem;
		z-index: 10;

		svg {
			margin-right: 0.5rem;
		}
	}

	@media only screen and (max-width: $mobile_break) {
		.media-slider__label {
			left: 0.5rem;
		}
	}

  .slick-slider {
    .slick-prev, .slick-next {
      border-radius: 4px;
    }
  }

  .media-slider__dot {
    height: 11px;
    width: 100%;
    background-color: $lightgray;
  }

  .slick-active {
    .media-slider__dot {
      background-color: $erste-darkblue;
    }
  }

  .slick-list .slick-track .slick-slide {
    height: 300px;
		margin-top: 0;
    img {
      height: 300px;
      width: auto !important;
    }
  }

  .slick-list {
    margin-left: calc((100vw - 1170px) / 2);
    margin-right: calc((100vw - 1170px) / 2);
  }


  // Arrows
  .carousel-slider {
    display: flex;
    flex-direction: row;

    > button {
      display: none !important;
      position: absolute;
      z-index: 50;

      &.slick-prev,
      &.slick-next {
        padding: 1rem;
        border: none;
        background-color: $cardlightgray;
        top: 7rem;
        height: 56px;
        width: 56px;
        border-radius: 100%;
        color: transparent;
        overflow: hidden;

        &::before {
          content: '';
          background-repeat: no-repeat;
          background-position: center;
          background-size: contain;
          position: absolute;
          width: 28px;
          height: 100%;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }

      &.slick-prev {
        left: 2rem;

        &::before {
          background-image: url(../../src/assets/icons/mediaslider_button_left.svg);
        }
      }

      &.slick-next {
        right: 2rem;
        &::before {
          background-image: url(../../src/assets/icons/mediaslider_button_right.svg);
        }
      }

      @media only screen and (min-width: $mobile_break) {
        display: block !important;

        &.slick-prev {
          left: calc(((100% - $max_tablet_width) / 2) - ($arrow_width / 2));
        }

        &.slick-next {
          right: calc((100% - $max_tablet_width) / 2 - ($arrow_width / 2));
        }
      }

      @media only screen and (min-width: $desktop_break) and (max-width: $max_header_width) {
        &.slick-prev {
          left: calc(((100% - $max_desktop_width) / 2) - 45px);
        }

        &.slick-next {
          right: calc((100% - $max_desktop_width) / 2 - 45px);
        }
      }

      @media only screen and (min-width: $max_header_width) {
        &.slick-prev {
          left: calc(((100% - $max_desktop_width) / 2) - 75px);
        }

        &.slick-next {
          right: calc((100% - $max_desktop_width) / 2 - 75px);
        }
      }
    }
  }

  .slick-dots {
    //display: flex !important;
    bottom: -16px;
    margin-bottom: 0 !important;
    max-width: 900px;
    margin-left: auto;
    margin-right: auto;

    li {
      width: 100%;
      margin: 0;
      padding: 1rem;
    }

    @media only screen and (max-width: $mobile_break) {
      display: flex !important;
      flex-direction: row;

      li {
        padding: 0;
        button {
          width: 100%;
          border-radius: 0%;
        }
      }
    }
  }
}
