@import '../../../../shared/styles/sreal_default/vars/colors.scss';
@import '../../../../shared/styles/sreal_default/vars/breaks.scss';

.searchForm {
  background-color: $erste-blue;
  padding: 1rem;
  border-radius: 5px;

  label,
  p {
    color: #fff;
  }

  label {
    display: none;
  }

  .searchForm__buttons:not(.searchForm__buttons--expanded) {
    > .button {
      width: fit-content;
      text-wrap: nowrap;
      margin-bottom: 0;
      margin-left: 0;
    }
  }

  @media all and (min-width: $mobile_break) {
    .searchForm__buttons {
      display: flex;
    }
  }
}

.MuiPaper-root.accordion--searchform {
  background-color: $erste-blue;
  padding: 0;
  border: 0px;
  margin-bottom: 0;

  .MuiButtonBase-root {
    padding: 0 1rem;
  }

  .MuiCollapse-root {
    border-top: 0px;
  }

  h2 {
    margin: 0.25rem 0;
    font-size: 1.1rem;
    color: #fff;
  }
}

.searchForm--longversion {
  width: calc(100% - var(--widthcalc) - 30px);
}

.searchForm__buttons--expanded {
  .button {
    width: 100%;
  }
  .searchForm__buttons--expanded__left {
    margin-bottom: 1rem;
  }
}


@media all and (min-width: $mobile_break) {
  .searchForm__buttons--expanded {
    justify-content: space-between;

    .button {
      width: auto;
      flex: 0 0 auto;
    }

    .searchForm__buttons--expanded__left {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-between;
      margin-bottom: 0;

      button {
        margin-right: 1rem;
      }
    }
   
  }
}
