@import '../../../../shared/styles/sreal_default/vars/colors.scss';
@import '../../../../shared/styles/sreal_default/vars/breaks.scss';

.checklisttable {
  margin: 40px 0;
}

.checklistTableGroup {
  margin-top: 40px;
}

.checklisttable,
.checklistTableGroup {
  .MuiFormControlLabel-label {
    font-size: 1.3rem;
    font-weight: 400;
    color: $darkgray;
  }
  .MuiBox-root {
    border: 1px solid $lightgray;
    border-radius: 0.4rem;
    margin-left: 12px;
    .MuiFormControlLabel-label {
      font-size: 1rem;
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
    }
    .MuiFormControlLabel-root:not(:last-child) {
      border-bottom: 1px solid $lightgray;
    }
  }
  .MuiFormControlLabel-root {
    margin-left: 0;
    margin-right: 0;
  }
}

.checklistTableGroup {
  &__checkbox {
    margin-bottom: -35px;
  }
}

.MuiTypography-root {
  font-family: Inter !important;
  color: $erste-darkblue !important;
  font-weight: 600 !important;
}

.MuiTypography-root {
  label {
    font-size: 1rem !important;
    font-weight: 600 !important;
  }
}
