@import "../../../../shared/styles/sreal_default/sreal_default_styles.scss";

@media all and (min-width: $mobile_break) {
  .desktop--box {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 2rem;
  }

  .desktop--box_item {
    flex: 0 0 48%;
    margin: 0 !important;
  }

  .desktop--firstrow {
    display: flex;
    flex-direction: column-reverse;
  }

  .desktop--topics {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .desktop--topic-item {
    flex: 0 0 48%;
    margin-bottom: 3rem !important;
    @media (min-width: $desktop_break) {
      li:nth-child(3n) {
        flex-basis: 100%;
      }
    }
  }
}