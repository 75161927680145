@import '../../../../shared/styles/sreal_default/vars/colors.scss';
@import '../../../../shared/styles/sreal_default/vars/breaks.scss';

.wohnwertlight-card {
  .wohnwertlight__content {
      padding: 2.5rem 2rem 2.5rem 2rem;
  }
}

.card {
  .card__content--wohnwert {
    padding: 2.5rem 2rem 2.5rem 2rem;
    display: flex;
    flex-direction: column;

    svg {
      transform-origin: center;
      margin-left: 0.5rem;
    }
  }
}

.card {
  .elements3cols__col {
    .card__content--wohnwert {
      min-height: unset;
    } 
  }
}

.wohnwertlight__formresult-wrapper {
  width: 100%;
  color: white;

  svg {
    margin-left: 0.5rem;
    width: 17px;
    height: auto;
  }
}

.wohnwertlight-card {
  .wohnwertlight__formresult-wrapper {
    width: 100%;
    color: white;
    border-radius: 5px;
    .button-panel {
      margin-bottom: 0;
    }
  }
}

@media all and (min-width: $mobile-break) {
  .wohnwertlight__formresult-wrapper > div:first-child {
    display: flex;
    gap: 12px;
    flex-wrap: wrap;
  }

  .pagedialog .wohnwertlight__formresult-wrapper > div:first-child {
    width: 100%;
    flex-direction: column;
    flex-wrap: wrap;
    gap: unset;
  }
}

.wohnwertlight__formresult {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: flex-end;
  justify-content: space-between;
  line-height: 1;
  margin-bottom: 1rem;

  .highlight {
    font-size: 2rem;
    text-align: right;
    font-weight: 600;
    flex: 1;
    padding-right: 0.5rem;
    white-space: pre;
  }

  .lowlight {
    display: flex;
    align-items: flex-end;
    font-size: 1.2rem;
  }
}

@media (min-width: $mobile_break) {
  .highlight {
    padding-left: 0.5rem;
    margin-bottom: -0.1rem;
  }
}

.wohnwertlight-form {
  background-color: $erste-blue;

  .wohnwertlight-form__content {
    padding: 1rem;
    box-sizing: border-box;
    background-color: $erste-blue;

    h3,
    label,
    p,
    span,
    a {
      color: #fff;
    }

    > .form__field:first-of-type {
      margin-bottom: 0;
    }

    .info-mark {
      background-color: #fff;
      margin-left: 1rem;
      width: 25px;
      height: 23px;

      span {
        color: $erste-blue;
      }
    }
  }

  .wohnwertlight-form__footer__registrationInformation {
    padding: 30px;
    background-color: #fff;

    h3 {
      margin-top: 0;
    }
  }
}

.pagedialog {
  .wohnwertlight-form {
    position: relative;
    display: flex;
    flex-direction: column;

    .wohnwertlight-form__content {
      flex-grow: 1;
    }
  }
}

// WOHNWERT PRO
.wohnwertpro-card {
  padding-bottom: 0;
}

.card__content--wohnwert {
  height: 100%;
  .wohnwertpro__content--withGutachten {
    height: calc(100% - 2.5rem);
    min-height: 10rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    svg {
      transform: scale(2);
      transform-origin: bottom left;
      margin-left: 0.5rem;
    }
    .textlink {
      font-size: 0.8rem;
      color: $erste-blue;
      padding-top: 0.5rem;
    }
  }
}

@media all and (min-width: $mobile_break) {
  .wohnwertpro-card {
    .card__content--wohnwert {
      .font-90 {
        margin-bottom: 0px;
      }
    }
  }
}
