@import "../../../../shared/styles/sreal_default/vars/colors.scss";

.info-mark {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  background-color: $erste-darkblue;
  color: white;
  border-radius: 50%;
  cursor: pointer;
  
  span {
    margin: 0;
    padding: 0;
    font-size: 1rem;
  }
}

.headline--withinfomark {
  position: relative;
  padding-right: 40px;

  .info-mark {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
  }
}
