@import "../vars/colors.scss";
@import "../vars/breaks.scss";

// FONT SIZES
.font-80 {
  font-size: 0.8rem;
}
.font-90 {
  font-size: 0.9rem;
}
.font-100 {
  font-size: 1rem;
}
.font-110 {
  font-size: 1.1rem;
}
.font-120 {
  font-size: 1.2rem;
}
.font-130 {
  font-size: 1.3rem !important;
}
.font-140 {
  font-size: 1.4rem !important;
}
.font-150 {
  font-size: 1.5rem !important;
}
.font-160 {
  font-size: 1.6rem !important;
}
.font-170 {
  font-size: 1.7rem;
}
.font-180 {
  font-size: 1.8rem !important;
}
.font-190 {
  font-size: 1.9rem;
}
.font-200 {
  font-size: 2rem !important;
}
.font-210 {
  font-size: 2.1rem;
}
.font-220 {
  font-size: 2.2rem !important;
}
.font-230 {
  font-size: 2.3rem !important;
}
.font-240 {
  font-size: 2.4rem !important;
}
.font-250 {
  font-size: 2.5rem !important;
}
.font-260 {
  font-size: 2.6rem;
}
.font-270 {
  font-size: 2.7rem;
}
.font-280 {
  font-size: 2.8rem;
}
.font-200 {
  font-size: 2.9rem;
}
.font-300 {
  font-size: 3rem;
}
.font-350 {
  font-size: 3.5rem;
}
.font-400 {
  font-size: 4rem;
}
.font-500 {
  font-size: 5rem;
}

// FONT WEIGHT
.font-weight-300 {
  font-weight: 300 !important;
}
.font-weight-400 {
  font-weight: 400 !important;
}
.font-weight-500 {
  font-weight: 500 !important;
}
.font-weight-600 {
  font-weight: 600 !important;
}
.font-weight-700 {
  font-weight: 700 !important;
}
.font-weight-800 {
  font-weight: 800 !important;
}
.font-weight-900 {
  font-weight: 900 !important;
}

// Line heights
.lh-100 {
  line-height: 1;
}
.lh-110 {
  line-height: 1.1;
}
.lh-120 {
  line-height: 1.2;
}
.lh-130 {
  line-height: 1.3;
}
.lh-140 {
  line-height: 1.4;
}
.lh-150 {
  line-height: 1.5;
}
.lh-160 {
  line-height: 1.6;
}
.lh-170 {
  line-height: 1.7;
}
.lh-180 {
  line-height: 1.8;
}
.lh-190 {
  line-height: 1.9;
}
.lh-200 {
  line-height: 2;
}

.text-underline {
  text-decoration: underline !important;
}

.hover-underline {
  &:hover {
    text-decoration: underline !important;
  }
}

.text-nodecoration {
  text-decoration: none !important;
}
.hover-underline {
  &:hover {
    text-decoration: underline !important;
  }
}
.text-center {
  text-align: center;
}
.text-center--mobile {
  text-align: center;
}
.text-right {
  text-align: right;
}

.text-overflow-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.text-uppercase {
  text-transform: uppercase;
}

// FLIESSTEXT

p {
  font-size: 16px;
}

// HEADLINES
h1,
h2,
h3,
h4,
h5,
p {
  margin: 0;
  padding: 0;
  border: 0;
  font-weight: 400;
  vertical-align: baseline;
  background: transparent;
}

h1 {
  font-size: 2em;
  font-weight: 600;
  margin-bottom: 1.5rem;
  color: $erste-darkblue;
  font-weight: 600;
}

h2 {
  font-size: 1.8em;
  color: $erste-darkblue;
  font-weight: 700;
  margin-top: 2rem;
  margin-bottom: 1rem;
}

h3 {
  font-size: 24px;
  margin-top: 1.5rem;
  margin-bottom: 1rem;
  font-weight: 600;
  color: $erste-darkblue;
}

h4 {
  font-size: 1.1em;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  font-weight: 600;
  color: $text-blue;
}

h5 {
  font-size: 1em;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  font-weight: 600;
  color: $erste-darkblue;
}

p {
  margin: 0.5rem 0;
  line-height: 1.5;
}

b,
strong {
  font-weight: 700;
}

a {
  color: $erste-blue;
  font-weight: 600;
  text-decoration: none;
}

a.no--hover:hover {
  text-decoration: none !important;
}

a:hover {
  text-decoration: underline;
}

.text-blue {
  color: $erste-blue !important;
}

.text-white {
  color: white !important;
}

.text-lightred {
  color: #FAD3D3 !important;
}

.text-darkblue {
  color: #1e3163 !important;
}

.text-bold {
  font-weight: 700;
}

@media all and (min-width: $mobile_break) {
  .text-center--mobile {
    text-align: left;
  }
}