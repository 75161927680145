@import '../../../../shared/styles/sreal_default/vars/colors.scss';

.bordertop--lightgray {
    border-top: 1px solid $lightgray;
}

.borderright--lightgray {
    border-right: 1px solid $lightgray;
}

.borderbottom--lightgray {
    border-bottom: 1px solid $lightgray;
}

.borderleft--lightgray {
    border-left: 1px solid $lightgray;
}