@import '../../../../shared/styles/sreal_default/vars/colors.scss';
@import '../../../../shared/styles/sreal_default/vars/breaks.scss';

.producthighlights {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    margin: 0;
    .producthighlights__text {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        margin-bottom: 1rem;
        h2, p {
        color: $gray;
        }
        h2 {
            text-align: center;
        } 
    }
    .producthighlights__boxes {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        gap: 4%;
        width: 100%;

        .box {
            border-radius: 0.8rem;
            padding: 1.5rem;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            position: relative;
            width: 100%;
            margin-bottom: 2rem;
            .button {
                width: fit-content;
                text-decoration: none;
                z-index: 2;
            }
            h4 {
                color: white;
                z-index: 2;
            }
            img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: 0.8rem;
                z-index: 1;
                filter: brightness(75%);
            }
        }
        .box:last-child {
            margin-bottom: 0;
        }
        
        .box-small{
            height: 16rem;           
        } 
        .box-large {
            height: 32rem;
            border-radius: 0.8rem;
        } 
    }
}

.producthighlights--fullWidth {
    display: block;
}

@media all and (min-width: $mobile_break) {
    .producthighlights {
        flex-direction: row;
        margin: 4rem 0;
        .producthighlights__boxes {
            height: 32rem;
            width: 55%;
            .box {
                width: 48%;
                margin-bottom: 0rem;
            }
            .box-small {
                height: 48%;
            }
        }
        .producthighlights__text {
            width: 40%;
            margin-bottom: 4rem;
            h2 {
                text-align: left;
            } 
        }
    }
    .producthighlights--fullWidth {
        .producthighlights__boxes {
            width: 100%;
            gap: 2%;
            justify-content: space-between;
            .box {
                width: 32%;
            }
            .box-small {
                height: 48%;
            }
        }
    
        .producthighlights__text {
            width: 100%;
            text-align: center;
            h2 {
                text-align: center;
            }
        }
    }
}