@import "../../../../shared/styles/sreal_default/vars/colors.scss";

.impressum, .datenschutz {
    p {
        color: $erste-darkblue;
    }
    &__list {
        display: flex;
        flex-direction: column;
    }
    a {
        color: $erste-blue !important;
        text-decoration: underline !important;
    }
    li {
        color: $erste-darkblue;
    }
}

.datenschutz {
    word-break: break-word;
    li {
        list-style-type: none;
      }
      
      li:before {
        content: '- ';
    }
}