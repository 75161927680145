@import "./vars/colors.scss";
@import "./vars/fonts.scss";
@import "./vars/breaks.scss";

@import "./utils/accordion.scss";
@import "./utils/spacing.scss";
@import "./utils/scale.scss";
@import "./utils/buttons.scss";
@import "./utils/form.scss";
@import "./utils/flexbox.scss";
@import "./utils/text.scss";
@import "./utils//widths.scss";
@import "./utils//lists.scss";
@import "./utils/images.scss";
@import "./utils/flexbox.scss";
@import "./utils/accordion.scss";
@import "./utils/heights.scss";
@import "./utils/border.scss";

//easier size changing // use @include device(..) to use
@mixin device($breakpoint) {
  @if $breakpoint == mobile {
    @media only screen and (max-width: $mobile_break) {
      @content;
    }
  }
  @if $breakpoint == desktop {
    @media only screen and (min-width: $mobile_break) and (max-width: $desktopbig_break) {
      @content;
    }
  }
}

// generall css classes
.position-relative {
  position: relative;
}

.position-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.position-relative {
  position: relative;
}

.position-absolute {
  position: absolute !important;
}

.is-hidden {
  display: none;
}
.is-visible {
  display: block;
  opacity: 1;
  visibility: visible;
}

.mobile-hidden {
  display: none;
  &.d-flex {
    display: none;
  }
}
@media (min-width: $mobile_break) {
  .mobile-hidden {
    display: block;
    &.d-flex {
      display: flex;
    }
  }
  .desktop-hidden {
    display: none !important;
    &.d-flex {
      display: none;
    }
  }
}

@media (min-width: $mobile_break) and (max-width: $desktop_break) {
  .tablet-hidden {
    display: none !important;
  }
}

.no-break {
  white-space: nowrap;
}

.no-scroll {
  overflow: hidden;
  position: fixed;
  z-index: 1;
}

.cursor-pointer {
  cursor: pointer;
}

.is-blurred {
  &::before {
    content: "";
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(50, 50, 50, 0.5);
    z-index: 10;
  }
}

.no-break {
  white-space: nowrap;
}
.textlink {
  color: $erste-blue;
  font-weight: 600;
  text-decoration: none;
  cursor: pointer;
}

.textlink:hover {
  text-decoration: underline;
}

.textlink--white {
  color: white;
}

.textlink--dark-blue {
  color: $erste-darkblue;
}

.nobreak {
  white-space: nowrap;
}

.headline--withicon {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: flex-start;
  margin-bottom: 1rem;
}

.circle {
  background-color: $erste-darkblue;
  width: 3rem;
  min-width: 3rem;
  height: 3rem;
  text-align: center;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  margin-right: 1rem;

  img,
  svg {
    height: 1.8rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 2.5rem;
  }
}

.circle--white {
  background-color: white;
}

#sreal-kundenportal-root {
  .background-stone {
    background-color: $newblue;
  }

  .background-lightstone {
    background-color: $light-stone;
  }

  .background-blue {
    background-color: $erste-blue;
  }

  .background-turquoise {
    background-color: $turquoise;
  }

  .background-darkblue {
    background-color: $erste-dark-blue;
  }

  .background-orange {
    background-color: $orange;
  }

  .background-apple {
    background-color: $apple;
  }

  .background-forest {
    background-color: $green;
  }

  .background-aubergine {
    background-color: $purple;
  }

  .background-pink {
    background-color: $pink;
  }

  .background-darkpink {
    background-color: $darkpink;
  }

  .background-teal {
    background-color: $turquoise;
  }

  .background-darkteal {
    background-color: $darkturquoise;
  }

  .background-white {
    background-color: white;
  }
}

@media all and (max-width: $mobile_break) {
  .w-mobile--slider {
    .headline--withicon {
      gap: 20px;
      align-items: center;
      margin-bottom: 2rem;
    }
  }
}

@media all and (min-width: $mobile_break) {
  .page__loadingmessage,
  .page__emptymessage {
    color: $text-blue;
  }
}