@import '../../../../shared/styles/sreal_default/vars/colors.scss';
@import '../../../../shared/styles/sreal_default/vars/breaks.scss';

.slick-list {
  height: 100%;
  width: 100%;

  .slick-track {
    display: flex;

    .slick-slide {
      height: auto;
      position: relative;

      .header__content-wrapper {
        max-width: calc(100% - 3rem); // 2 * 2.5rem margin
      }

      .card {
        margin: 0;
      }
    }
  }
}

.carousel-slider {
  .slick-track {
    .slick-slide {
      .card {
        margin-right: 1rem;
      }
    }
    .slick-slide:last-child .card {
      margin-right: 0;
    }
  }
}

.slick-dots {
  width: 100%;
  position: absolute;
  bottom: -45px;
  text-align: center;
  margin: 1rem 0;
  z-index: 5;

  li {
    display: inline;
    margin: 0 10px;

    button {
      background-color: $midgray;
      border: 1px solid $midgray;
      border-radius: 50%;
      color: transparent;
      padding: 3px;
      height: 3px;
      width: 3px;
    }

    &.slick-active button {
      background-color: $erste-blue;
      border-color: $erste-blue;
    }
  }
}

.header--slider {
  .slick-dots {
    bottom: 0;
  }
	
	&.header--purplebg {
		h1  {
			color: #fff;
		}
		
		.statsheader-container {
			color: #fff;
			
			h1, h2, h3, h4, p, span, li, a {
				color: #fff;
			}
			
			
			.slick-dots {
				li {
					&.slick-active button {
						background-color: #fff;
						border-color: #fff;
					}
				}
			}
		}
	}
}

.carousel-wrapper {
  max-width: 1315px;
  margin-left: auto;
  margin-right: auto;
}

.carousel-wrapper--news-mobile {
  .slick-list {
    margin-bottom: 3rem;
    .slick-slide > div {
      height: 100%;
    }
    .news-card {
      height: 100%;
      .card {
        height: 100%;
        .card__content {
          height: calc(100% - 185px);
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        }
      }
    }
  }
  .slick-dots {
    bottom: -20px;
  }
}

.slick-track {
  // width: 1730px !important;
}
.slick-slider {
  display:flex;
  flex-direction: row;
  gap: 1rem;
  justify-content: center;
  align-items: center;
    width: 100% !important;

  .slick-slide {
    margin: 0.5rem;
  }

  .slick-prev, .slick-next {
    border: none;
    width: 50px;
    height: 50px;
    border-radius: 100%;

    &.slick-disabled {
      visibility: hidden !important;
    }

    &:hover {
      background-color: $midgray;
    }

    svg {
      transform: scale(0.8);
    }
  }
}

.header--withimage {
  .slick-slider {
    h2, p {
      color: $erste-darkblue !important;
    }
  }
}

.carousel-slider.carousel--showallondesktop {
  .slick-track {
    justify-content: space-between;

    .slick-slide {
      width: calc(100% - 1rem);
      flex: 0 calc(100% - 1rem);
    }

    .slick-slide:last-child .card {
      width: calc(100% - 2px);
    }
  }
}

@media (max-width: $mobile_break) {
  .carousel--list {
    .slick-track {
      flex-direction: column;
      .slick-slide {
        width: 100% !important;
        margin-bottom: 2rem;
        .card {
          margin-right: 1px !important;
        }
      }
    }
  }

  .slick-customdots {
    display: none;
  }
}

@media (min-width: $mobile_break) {
  .carousel-slider {
    margin-left: 0 !important;
    width: 100% !important;
  }
  .carousel-slider:not(.carousel--showallondesktop) {
    margin-left: calc((100% - 1170px) / 2);
    width: calc(100% - ((100% - 1170px) / 2));
  }

  .carousel-wrapper {
    .slick-slide:first-child:nth-last-child(1) {
      margin-left: 70px;
    }
  }

  .carousel-wrapper.searchprofiles {
    .slick-slide:first-child:nth-last-child(1) {
      margin-left: 0;
    }
  }
}

.carousel__children {
  display: none;
}

@media (min-width: $mobile_break) {
  .carousel__children {
    order: 2;
    display: block;
  }
  .slick-dots {
    margin-top: 3rem;
    margin-bottom: 0;
    position: unset;
  }
  .MuiDialog-container {
    justify-content: flex-end !important;
    margin: 0 !important;
    height: unset !important;
    .MuiPaper-root {
      height: calc(100vh + 1rem) !important;
      margin: 0;
      width: 420px;
    }
  }
  .header--slider {
    .slick-dots {
      position: inherit;
    }
  }
}
