// MARGIN
.m-0 { margin: 0 !important;}
.m-10 { margin: 10px; }
.m-20 { margin: 20px; }
.m-30 { margin: 30px; }
.m-40 { margin: 40px; }
.m-50 { margin: 50px; }
.m-60 { margin: 60px; }
.m-70 { margin: 70px; }
.m-80 { margin: 80px; }
.m-90 { margin: 90px; }
.m-100 { margin: 100px; }

.mt-0 { margin-top: 0px !important; }
.mt-10 { margin-top: 10px; }
.mt-20 { margin-top: 20px; }
.mt-30 { margin-top: 30px !important; }
.mt-40 { margin-top: 40px; }
.mt-50 { margin-top: 50px; }
.mt-60 { margin-top: 60px; }
.mt-70 { margin-top: 70px; }
.mt-80 { margin-top: 80px; }
.mt-90 { margin-top: 90px; }
.mt-100 { margin-top: 100px; }
.mt-auto {margin-top: auto;}

.mr-0 { margin-right: 0px; }
.mr-10 { margin-right: 10px; }
.mr-20 { margin-right: 20px; }
.mr-30 { margin-right: 30px; }
.mr-40 { margin-right: 40px; }
.mr-50 { margin-right: 50px; }
.mr-60 { margin-right: 60px; }
.mr-70 { margin-right: 70px; }
.mr-80 { margin-right: 80px; }
.mr-90 { margin-right: 90px; }
.mr-100 { margin-right: 100px; }

.mb-0 { margin-bottom: 0px !important; }
.mb-5 { margin-bottom: 5px !important; }
.mb-10 { margin-bottom: 10px; }
.mb-20 { margin-bottom: 20px; }
.mb-30 { margin-bottom: 30px; }
.mb-40 { margin-bottom: 40px; }
.mb-50 { margin-bottom: 50px; }
.mb-60 { margin-bottom: 60px; }
.mb-70 { margin-bottom: 70px; }
.mb-80 { margin-bottom: 80px; }
.mb-90 { margin-bottom: 90px; }
.mb-100 { margin-bottom: 100px; }

.ml-0 { margin-left: 0px !important; }
.ml-10 { margin-left: 10px; }
.ml-20 { margin-left: 20px; }
.ml-30 { margin-left: 30px; }
.ml-40 { margin-left: 40px; }
.ml-50 { margin-left: 50px; }
.ml-60 { margin-left: 60px; }
.ml-70 { margin-left: 70px; }
.ml-80 { margin-left: 80px; }
.ml-90 { margin-left: 90px; }
.ml-100 { margin-left: 100px; }

// MARGIN REM

.m-0rem { margin: 0rem; }
.m-50rem { margin: 0.5rem; }
.m-100rem { margin: 1rem; }
.m-150rem { margin: 1.5rem; }
.m-200rem { margin: 2rem; }
.m-250rem { margin: 2.5rem; }
.m-300rem { margin: 3rem; }
.m-350rem { margin: 3.5rem; }
.m-400rem { margin: 4rem; }
.m-450rem { margin: 4.5rem; }
.m-500rem { margin: 5rem; }

.mt-0rem { margin-top: 0rem; }
.mt-50rem { margin-top: 0.5rem; }
.mt-75rem { margin-top: 0.75rem; }
.mt-100rem { margin-top: 1rem !important; }
.mt-150rem { margin-top: 1.5rem !important; }
.mt-200rem { margin-top: 2rem; }
.mt-250rem { margin-top: 2.5rem; }
.mt-300rem { margin-top: 3rem !important; }
.mt-350rem { margin-top: 3.5rem; }
.mt-400rem { margin-top: 4rem; }
.mt-450rem { margin-top: 4.5rem; }
.mt-500rem { margin-top: 5rem; }
.mt-600rem { margin-top: 6rem; }

.mr-0rem { margin-right: 0rem; }
.mr-50rem { margin-right: 0.5rem; }
.mr-75rem { margin-right: 0.75rem; }
.mr-100rem { margin-right: 1rem; }
.mr-150rem { margin-right: 1.5rem; }
.mr-200rem { margin-right: 2rem; }
.mr-250rem { margin-right: 2.5rem; }
.mr-300rem { margin-right: 3rem; }
.mr-350rem { margin-right: 3.5rem; }
.mr-400rem { margin-right: 4rem; }
.mr-450rem { margin-right: 4.5rem; }
.mr-500rem { margin-right: 5rem; }

.mb-0rem { margin-bottom: 0rem; }
.mb-10rem { margin-bottom: 0.1rem; }
.mb-50rem { margin-bottom: 0.5rem !important; }
.mb-100rem { margin-bottom: 1rem; }
.mb-150rem { margin-bottom: 1.5rem; }
.mb-200rem { margin-bottom: 2rem !important; }
.mb-250rem { margin-bottom: 2.5rem; }
.mb-300rem { margin-bottom: 3rem !important; }
.mb-350rem { margin-bottom: 3.5rem; }
.mb-400rem { margin-bottom: 4rem; }
.mb-450rem { margin-bottom: 4.5rem; }
.mb-500rem { margin-bottom: 5rem !important; }
.mb-1000rem { margin-bottom: 10rem !important; }

.mb--700rem { margin-bottom: -7rem; }

.ml-0rem { margin-left: 0rem; }
.ml-50rem { margin-left: 0.5rem; }
.ml-100rem { margin-left: 1rem; }
.ml-150rem { margin-left: 1.5rem; }
.ml-200rem { margin-left: 2rem; }
.ml-250rem { margin-left: 2.5rem; }
.ml-300rem { margin-left: 3rem; }
.ml-350rem { margin-left: 3.5rem; }
.ml-400rem { margin-left: 4rem; }
.ml-450rem { margin-left: 4.5rem; }
.ml-500rem { margin-left: 5rem; }

.m-auto { margin: auto; }

// PADDING
.p-0 { padding: 0px !important; }
.p-5 { padding: 5px; }
.p-10 { padding: 10px; }
.p-20 { padding: 20px; }
.p-30 { padding: 30px; }
.p-40 { padding: 40px; }
.p-50 { padding: 50px; }
.p-60 { padding: 60px; }
.p-70 { padding: 70px; }
.p-80 { padding: 80px; }
.p-90 { padding: 90px; }
.p-100 { padding: 100px; }

.pt-0 { padding-top: 0px !important; }
.pt-5 { padding-top: 5px; }
.pt-10 { padding-top: 10px; }
.pt-20 { padding-top: 20px; }
.pt-30 { padding-top: 30px; }
.pt-40 { padding-top: 40px; }
.pt-50 { padding-top: 50px; }
.pt-60 { padding-top: 60px; }
.pt-70 { padding-top: 70px; }
.pt-80 { padding-top: 80px; }
.pt-90 { padding-top: 90px; }
.pt-100 { padding-top: 100px; }

.pr-0 { padding-right: 0px; }
.pr-5 { padding-right: 5px; }
.pr-10 { padding-right: 10px; }
.pr-20 { padding-right: 20px; }
.pr-30 { padding-right: 30px; }
.pr-40 { padding-right: 40px; }
.pr-50 { padding-right: 50px; }
.pr-60 { padding-right: 60px; }
.pr-70 { padding-right: 70px; }
.pr-80 { padding-right: 80px; }
.pr-90 { padding-right: 90px; }
.pr-100 { padding-right: 100px; }

.pb-0 { padding-bottom: 0px !important; }
.pb-5 { padding-bottom: 5px; }
.pb-10 { padding-bottom: 10px; }
.pb-20 { padding-bottom: 20px; }
.pb-30 { padding-bottom: 30px; }
.pb-40 { padding-bottom: 40px; }
.pb-50 { padding-bottom: 50px; }
.pb-60 { padding-bottom: 60px; }
.pb-70 { padding-bottom: 70px; }
.pb-80 { padding-bottom: 80px; }
.pb-90 { padding-bottom: 90px; }
.pb-100 { padding-bottom: 100px; }

.pl-0 { padding-left: 0px; }
.pl-3 {padding-left: 3px;}
.pl-5 { padding-left: 5px; }
.pl-10 { padding-left: 10px; }
.pl-20 { padding-left: 20px; }
.pl-30 { padding-left: 30px; }
.pl-40 { padding-left: 40px; }
.pl-50 { padding-left: 50px; }
.pl-60 { padding-left: 60px; }
.pl-70 { padding-left: 70px; }
.pl-80 { padding-left: 80px; }
.pl-90 { padding-left: 90px; }
.pl-100 { padding-left: 100px; }

// PADDING REM
.p-0rem { padding: 0rem; }
.p-50rem { padding: 0.5rem; }
.p-100rem { padding: 1rem; }
.p-150rem { padding: 1.5rem; }
.p-200rem { padding: 2rem; }
.p-250rem { padding: 2.5rem; }
.p-300rem { padding: 3rem; }
.p-350rem { padding: 3.5rem; }
.p-400rem { padding: 4rem; }
.p-450rem { padding: 4.5rem; }
.p-500rem { padding: 5rem; }

.pt-0rem { padding-top: 0rem !important; }
.pt-50rem { padding-top: 0.5rem; }
.pt-100rem { padding-top: 1rem; }
.pt-150rem { padding-top: 1.5rem; }
.pt-200rem { padding-top: 2rem; }
.pt-250rem { padding-top: 2.5rem; }
.pt-300rem { padding-top: 3rem; }
.pt-350rem { padding-top: 3.5rem; }
.pt-400rem { padding-top: 4rem; }
.pt-450rem { padding-top: 4.5rem; }
.pt-500rem { padding-top: 5rem; }

.pr-0rem { padding-right: 0rem; }
.pr-50rem { padding-right: 0.5rem; }
.pr-100rem { padding-right: 1rem; }
.pr-150rem { padding-right: 1.5rem; }
.pr-200rem { padding-right: 2rem; }
.pr-250rem { padding-right: 2.5rem; }
.pr-300rem { padding-right: 3rem; }
.pr-350rem { padding-right: 3.5rem; }
.pr-400rem { padding-right: 4rem; }
.pr-450rem { padding-right: 4.5rem; }
.pr-500rem { padding-right: 5rem; }

.pb-0rem { padding-bottom: 0rem; }
.pb-50rem { padding-bottom: 0.5rem; }
.pb-100rem { padding-bottom: 1rem !important; }
.pb-150rem { padding-bottom: 1.5rem; }
.pb-200rem { padding-bottom: 2rem !important; }
.pb-250rem { padding-bottom: 2.5rem; }
.pb-300rem { padding-bottom: 3rem; }
.pb-350rem { padding-bottom: 3.5rem; }
.pb-400rem { padding-bottom: 4rem; }
.pb-450rem { padding-bottom: 4.5rem; }
.pb-500rem { padding-bottom: 5rem; }
.pb-700rem { padding-bottom: 7rem; }

.pl-0rem { padding-left: 0rem; }
.pl-50rem { padding-left: 0.5rem; }
.pl-100rem { padding-left: 1rem; }
.pl-150rem { padding-left: 1.5rem; }
.pl-200rem { padding-left: 2rem; }
.pl-250rem { padding-left: 2.5rem; }
.pl-300rem { padding-left: 3rem; }
.pl-350rem { padding-left: 3.5rem; }
.pl-400rem { padding-left: 4rem; }
.pl-450rem { padding-left: 4.5rem; }
.pl-500rem { padding-left: 5rem; }

.gap-100rem { gap: 1rem; }
.gap-200rem { gap: 2rem; }