@import '../../../../shared/styles/sreal_default/vars/colors.scss';
@import '../../../../shared/styles/sreal_default/vars/breaks.scss';

.card-link {
  text-decoration: none;
}


.card--linkOnFullCard:hover {
  box-shadow: rgba(11, 32, 66, 0.14) 0px 4px 14px 2px;
}

.infrastructure-header {
  flex-direction: column;
  h2 {
    text-align: center;
    margin-bottom: 2rem !important;
  }
  .filtermenu {
    margin-bottom: 1rem;
  }
  .filtermenu-wrapper-right {
    margin-left: 0;
    width: calc(100% + 2rem);
  }
}

.immo-card__label {
  color: white;
  width: fit-content;
  height: fit-content;
  border-radius: 5px;
  position: absolute;
  top: calc(-185px + 1.5rem);
  padding: 0.5rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 0.25rem;
}

.news-card__teaser {
  .cardText {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
  }
}

.immo-drive {
  .form__field {
    width: 24rem;
    max-width: calc(375px - 2rem);
  }
  .immo-drive__immo-header {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    flex-direction: column;
  }
}

.news-card__label {
  width: fit-content;
  height: fit-content;
  border-radius: 5px;
  position: absolute;
  left: 0.8rem;
  top: calc(-185px + 0.5rem);
  padding: 0.5rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 0.25rem;
}

.news-card-highlight__label {
  top: -16rem;
}

.immo-card__label--active {
  background-color: $erste-blue;
} 

.immo-card__label--completed {
  background-color: $green;
}

.immo-card--active, .immo-card--completed {
  .card.bg-white {
    background-color: $erste-blue;
    color: white;
    h3, span {
      color: white !important;
    }

  }
}

.immo-card--completed {
  .card.bg-white {
    background-color: $green;
  }
}

.cardgrid {
  .immo-card.immo-card--residential {
    margin-bottom: 3rem;
    display: flex;
    flex-direction: column;
    width: 100%;
    box-shadow: 0px 2px 8px 0px rgba(11, 31, 66, 0.1019607843), 0px 1px 1px 0px rgba(11, 31, 66, 0.0392156863);
    border-radius: 12px;
    .card-link {
      display: block;
      width: 100%;
      .card {
        box-shadow: none;
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
      }
    }
    .subunits {
      width: 100%;
      .subunits__immos {
        box-shadow: none;
        margin-top: 0;
        td {
          border-top: 1px solid $lightgray;
          padding: 1.5rem;
        }
      }
    }
  }
}

.cardgrid--4cols {
  margin-top: 2rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: flex-start;
  > div {
    flex: 0 0 calc(23.9%);

    > a > .card {
      margin-bottom: 0;
    }
  }
}

@media all and (min-width: $mobile_break) and (max-width: $desktop_break) {
  .cardgrid--4cols {
    > div {
      flex: 0 0 calc(48%);
    }
  }
}

@media all and (max-width: $mobile_break) {
  .cardgrid--4cols {
    > div {
      flex: 0 0 calc(100%);
    }
  }
}

.card--energy-wrapper__cards {
  justify-content: center;
  gap: 1rem;
}

.card--energy-wrapper {
  gap: 4%;
  background-color: #F4F6FA;
  justify-content: center;
  border-radius: 12px;
  .card--energy {
    width: fit-content !important;
  }
}

.card.card--energy {
  width: 100%;
  padding-top: 2rem;
}
.card.card--energy-desktop {
  .immodetail__features-wrapper {
    margin: 1.5rem auto 0 auto;
  }
}

.card.card--infrastructure {
  margin-left: -2rem;
  width: calc(100% + 4rem);
  border-radius: 0;
  margin-bottom: 0;
  h3 {
    text-align: center;
  }
  .infrastructure__line--desktop {
    position: absolute;
    width: 88%;
    right: 48px;
    z-index: 1;
    margin-top: 3rem;
    display: none;
  }
  .infrastructure__line--mobile {
    position: absolute;
    margin-left: 1.6rem;
    height: calc(100% - 0.4rem);
    width: 0.75rem;
    background-color: white;
    margin-top: 0.2rem;
    margin-bottom: 0.2rem;
  }
  .infrastructure {
    position: relative;
  }
  .infrastructure__categories__circle-wrapper {
    display: flex;
    gap: 0.25rem;
    align-items: center;
    flex-direction: row;
    width: fit-content;
    p {
      order: 3;
      margin-bottom: 0;
      margin-left: 0.5rem;
      white-space: nowrap;
    }
    span {
      order: 2;
      margin-left: 0.5rem;
      white-space: nowrap;
    }
    .infrastructure__categories__circle {
      order: 1;
    }
  }
  .infrastructure__categories {
    flex-direction: column;
    display: flex;
    justify-content: space-between;
    width: 75%;
    z-index: 2;
    gap: 2rem;
  }
  .infrastructure__categories__circle {
    border-radius: 3rem;
    height: 4rem;
    width: 4rem;
    align-content: center;
    justify-content: center;
    display: flex;
    align-items: center;
    svg {
      height: 2.5rem;
      width: 2.5rem;
    }
  }
  .infrastructure__standort {
    display: none;
    svg {
      height: 4rem;
      width: 4rem;
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
    }
    span {
      height: 19.5px;
      margin-bottom: 0.25rem;
    }
    p {
      margin-top: 0.25rem;
    }
  }
}

#marktinfoLight{
  .card {
    .card__content {
      background-color: $lightstone;
      color: white;

      h2 {
        color: white;
      }

      h3 {
        color: white;
      }

      .form__field {
        label {
          color: white !important;
        }
      }
    }
  }
}

.card {
  border-radius: 12px;
  background-color: $lightgray-backgrounds;
  margin-bottom: 2rem;
  overflow: hidden;
  color: $gray;

  .card__content {
    position: relative;
    padding: 1.5rem;
  }

  .card__content--deaktiv, .card__content--deaktiv h4 {
    color: $deactiv;
    path {
      fill: $deactiv;
    }
  }

  &.card--transparent {
    background-color: transparent;
  }

  &.with-shadow {
    box-shadow: 0px 2px 8px 0px #0B1F421A, 0px 1px 1px 0px #0B1F420A;
  }

  &.bg-white {
    background-color: #fff;
  }

  &.vertical {
    margin-bottom: 0;
    height: 48%;
    width: 100%;
  }

  &.card--placeholder {
    border: none;
    background-color: transparent;
    box-shadow: none;
    cursor: initial;
  }

  &.card--placeholder:hover {
    box-shadow: none;
  }

  .card__content--background {
    padding: 2.5rem 2rem 2.5rem 2rem;
    display: flex;
    flex-direction: column;
  }

  @media (min-width: $mobile_break) {
    &__content--wohnwert {
      padding-top: 3.5rem;
      padding-bottom: 3.5rem;
    }
  }

  h2,
  h3,
  p {
    margin-top: 0;
    margin-bottom: 1rem;
  }

  h2 {
    font-size: 1.6em;
  }

  p {
    line-height: 1.5;
  }

  .cardMeta {
    margin-top: 0;
    margin-bottom: 1rem;
    font-size: 1rem;
    color: $gray;
  }

  .cardText {
    font-size: 0.9rem;
    font-weight: 400;
  }

  &.linkOnFullCard {
    cursor: pointer;
  }

  &.standard,
  &.topImage {
    background-image: none !important;

    img {
      width: 100%;
    }
  }

  &.topImage {
    img {
      max-height: 250px;
      min-height: 120px;
      object-fit: cover;
      object-position: center;
    }
    .topImage_icon {
      display: flex;
      justify-content: center;
      background-color: $erste-blue;
      max-height: 250px;
      min-height: 150px;
      object-fit: cover;
      object-position: center;
      align-items: center;
    }
    @media (min-width: $mobile_break) {
      .topImage_icon {
        width: 100%;
        max-height: unset;
        min-height: 185px;
      }
      img {
        max-height: unset;
      }
    }
  }

  &.bottomImage {
    display: flex;
    flex-direction: column;
    background-image: none;
    background-image: none !important;

    img {
      order: 1;
      width: auto;
      margin: 20px auto 0 auto;
    }
  }

  .card__dropdowntext {
    p {
      margin-bottom: 0;
    }
  }

  .dot-unread {
    margin-left: auto;
    margin-right: 1rem;
    margin-bottom: 1rem;
    margin-top: -1.5rem;
  }

  &.fullsizeImage {
    background-size: cover;
    background-position: center;
    color: #fff;

    .card__content {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      justify-content: space-between;
      min-height: 30rem;
    }
  }

  &.teaserMini {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px;

    &.cursor-pointer {
      cursor: initial ;
    }

    .teaserMini__headline {
      display: block;
      margin: 0;
      min-height: 60px;
      max-width: 170px;
      line-height: 1.25;
    }

    .teaserMini__text,
    .textlink {
      padding-top: 5px;
      font-size: 0.9rem;
    }

    .teaserMini__icon {
      display: flex;
      align-items: center;
      width: 100%;
      min-height: 100px;
      padding-top: 20px;
      margin-bottom: 30px;
      box-sizing: border-box;

      svg {
        width: 100%;
      }
    }
  }

  .immo-card__bottom--label {
    color: $gray;
  }

  @media (min-width: $mobile_break) {
    .teaserMini__icon {
      position: absolute;
      right: 52px;
      width: fit-content !important;
    }
    .teaserMini__headline {
      order: 2;
      max-width: 190px !important;
      margin-bottom: 2rem !important;
    }
  }

  &.teaserMiniImage {
    height: 6rem;
    display: flex;
    flex-direction: row;
    padding: 0.8rem;
    position: relative;

    .immo-card__image--container {
    }

    .immo-card__image {
      width: 4.6rem;
      height: 4.6rem;
      border-radius: 10%;
      margin-right: 0.8rem;
      display: flex;
      align-items: center;
      justify-content: center;

      &.bg-white {
        background-color: white;
      }
    }

    .immo-card__content--container {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
    }

    .immo-card__content--container-small {
      display: flex;
      justify-content: center;
      flex-direction: column;
      height: 100%;
    }

    .immo-card__icon--container {
      position: absolute;
      right: 0.8rem;
      bottom: 0.8rem;
    }

    &.bg-blue {
      background-color: $erste-blue;
    }
  }

  .MuiAccordionSummary-content {
    color: $erste-darkblue;
    margin: 15px 0 !important;

    h2,
    h3 {
      margin-bottom: 0;
      font-weight: 600;
      font-size: 1.6rem;
    }
  }

  .MuiAccordionSummary-root.Mui-expanded {
    min-height: 56px;
  }

  .MuiAccordionSummary-expandIconWrapper {
    svg {
      height: auto;
      width: 20px;
    }
  }

  .card__dropdown {
    border-top: 1px solid $lightgray;
    margin-left: -1.5rem;
    margin-right: -1.5rem;
    margin-bottom: -1.5rem;
    margin-top: 1.5rem;

    .MuiAccordion-root {
      border: 0 !important;

      box-shadow: none;
      margin: 0;

      h3 {
        font-size: 1.2rem;
      }
    }
  }
}

.card--glass {
  background-color: rgba(255, 255, 255, 0.3);
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
}

.card--finanzierung {
  .button-panel {
    .button--blue {
      border: 1px solid #2870ED !important;
    }
    .button {
      margin-right: 0;
      width: 100%;
      text-align: center;
    }
  }
}

.kaufanbot-card {
  min-height: 30rem;
   .topImage_icon {
    background-color: $apple !important;
   }
   .card__dropdown {
    .MuiPaper-root {
    background-color: $lightgray-backgrounds;
    }
   }

   label {
    color: $gray;
    font-weight: 600;
    font-family: Inter;
    font-size: 1rem;
  }
}

.card.elements2cols__col {
  .card__content:not(.card__content--background) {
    min-height: 350px;
  }
}

.progressdok-card,
.uebergabe-card,
.geldfluss-card,
.recommendation-card {
  .card__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }

  h2 {
    padding: 0;
    margin: 0;
    margin-bottom: 0.5rem;
  }
}

.uebergabe-card {
  .uebergabe-card__status {
    display: flex;
    flex-direction: row;
    padding: 2rem 0;
    align-items: center;
    justify-content: center;

    svg {
      min-width: 3.5rem;
    }

    h3 {
      font-size: 2rem;
      margin: 0;
      margin-left: 1rem;
    }
  }

  &.uebergabe-card__finished {
    background-color: $erste-blue;

    h2,
    h3,
    span {
      color: white;
      margin-bottom: 0;
    }
  }
}

.geldfluss-card {
  background-color: $erste-blue;
}

.geldfluss-card,
.recommendation-card {
  h2,
  p,
  span {
    color: white;
  }
}

#besichtigungen-cards {
  align-items: stretch;
  min-height: unset;
}

.cardgrid {
  display: flex;
  flex-direction: column;

  .immo-card .card,
  .news-card .card {
    width: 100% !important;
  }

  .card--placeholder {
    display: none;
  }
  .news-card .newsSkeletonCard {
    width: 100% !important;
    margin-bottom: 1rem;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    border: 1px solid $lightgray;
    padding: 1.5rem;
    padding-top: 0;
    background: white;
  }
  /*.react-loading-skeleton {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }*/
}
.card__wrapper__background {
  background-color: $verylightgray;
}

.news-card {
  display: inline-block;
  cursor: pointer;
  text-decoration: none !important;
  p {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
  }
}

.news-card--active {
  .card {
    color: white;
    background-color: $light-stone;
    .cardMeta {
      color: white;
    }
  }
}

.searchprofil-withoutimmos-card {
  // max-height: 380px;
  h4 {
    margin-top: 0;
  }
}

@media all and (min-width: $mobile_break) {
  .card--energy-wrapper {
    background-color: transparent;
    justify-content: unset;
    .card--energy {
      width: 100% !important;
    }
  }
  .immo-card,
  .immo-card .card {
    height: 408px;

    .card__content {
      position: relative;
      min-height: 220px;
      padding-bottom: 4rem;

      h3 {
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
      }
    }
  }
  .immo-drive {
    .immo-card,
    .immo-card .card {
      height: 20rem;
    }
    .immo-drive__immo-header {
      display: flex;
      justify-content: space-between;
      align-items: baseline;
      flex-direction: row;
    }
  }

  .card__content--background {
    p {
    height: 4rem;
    }
  }

  .cardgrid {
    display: flex;
    flex-direction: row;
    flex: 0 0 100%;
    width: 100%;
    flex-wrap: wrap;
    align-items: flex-start;
    box-sizing: border-box;
    gap: 2%;
    min-height: 470px;

    .news-card {
      margin-bottom: 0 !important;
    }

    .news-card,
    .immo-card,
    .card {
      width: 48%;
      // height: 490px;
      margin-bottom: 2rem;
      &__teaser {
        color: $gray;
        text-align: left;
        gap: 5px;
      }
    }
    .news-card .newsSkeletonCard {
      height: 260px;
    }

    

    .news-card,
    .news-card .card {
      min-height: 470px;
    }
  }

  .cardgrid {
    .immo-card.immo-card--residential {
      flex-direction: row;
      .card-link {
        width: 32%;
        .card {
          border-right: 1px solid $lightgray;
        }
      }
      .subunits {
        width: 68%;
        thead {
          td {
          padding: 1rem 0.45rem;
          }
        }
        .subunits__immos {
          margin-top: 0.45rem;
          td {
            padding: 0.45rem;
            border: none;
            img {
              height: 2.2rem;
            }
          }
          td:first-child {
            padding-left: 1rem;
            width: 2rem;
          }
          td:nth-last-child(2) {
            width: 1rem;
          }
          td:last-child {
            padding-right: 1rem;
          }
        }
      }
    }
  }

  .cardgrid:has(.card--vorteile) {
    min-height: unset;
    margin-bottom: 0;
  }

  .news-card {
    .card__content {
      position: relative;
      height: 280px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      h2 {
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
      }
    }
  }

  .searchprofil-withoutimmos-card {
    .card__content {
      padding-bottom: 0 !important;
      padding-top: 1rem;
      height: 408px;

      p {
        font-size: 0.9rem;
      }
    }
  }


  .immo-card {
    .card {
      position: relative;
      .card__content {
        a {
          display: block;
        }
      }
      .immo-card__bottom {
        position: absolute;
        bottom: 1.5rem;
        width: calc(100% - 3rem);

        &.w-100 {
          width: 100%;
        }
      }
    }
  }

  .card {
    &.topImage--2cols {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-items: stretch;

      img,
      .card__content {
        flex: 0 0 50%;
        width: 50%;
        height: auto;
      }
      img {
        border-radius: 0.4rem 0 0 0.4rem;
      }
    }
    .landingpage-examples {
      margin-top: 2rem;
      .line {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 1rem;
        
        svg {
          width: 20px;
          height: 20px;
          margin-right: 1rem;
          min-width: 4%;
        }
      }
    }

    &.topImage--2cols--right {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-items: stretch;

      img,
      .card__content {
        flex: 0 0 60%;
        height: auto;
      }
      .card__content {
        width: 60%;
      }
      img {
        border-radius: 0.4rem 0 0 0.4rem;
      }
    }

    &.topImage--2cols--left {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-items: stretch;

      .card__content {
        flex: 0 0 60%;
        height: auto;
      }
      .card__content {
        width: 60%;
      }
      img {
      }
    }

    .MuiAccordionSummary-expandIconWrapper {
      transform: rotateZ(-90deg) !important;
    }
  }
}

.landingpage-examples {
  margin-top: 2rem;
  .line {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 1rem;

    .lineicon {
      width: 9%;
      svg {
        width: 20px;
        height: 20px;
        margin-right: 1rem;
        min-width: 4%;
      }
    }

    .linetext {
      width: 91%;
      text-align: left;
    }
  }
}

@media all and (max-width: $desktop_break) {
  /* PROFILANALYSEN CARD */
  .profilAnalysen-card {
    .topImage_icon {
      svg {
        width: 7rem;
      }
    }
  }
}

@media all and (min-width: $mobile_break) and (max-width: desktop_break) {
  .cardgrid {
    background-color: red !important;
  }

}

@media all and (min-width: 400px) {
  .card.card--energy-desktop {
    .immodetail__features-wrapper--big {
      min-width: 138px;
    }
  }
}

@media all and (min-width: $desktop_break) {
  .immo-card {
    .immo-card__2col {
      display: flex;
      flex-direction: row;
    }
  }
  .cardgrid {
    .immo-card.immo-card--residential {
      .subunits {
        .subunits__immos {
          td:first-child {
            width: 10.5%;
          }
          td:nth-last-child(2) {
            width: 10.5%;
          }
        }
      }
    }
  }
  .landingpage-examples-wrapper {
    width: 60%;
    transform: translate(50%);
  }

  .cardgrid {
    .immo-card,
    .news-card,
    .card {
      width: 32%;
    }

    .card--placeholder {
      display: block;
      &.is-hidden {
        display: none;
      }
    }

    &.news__maincards {

      .card--placeholder {
        display: none;
      }
    }
  }

  .card--finanzierung {
    .card__content {
      height: 550px !important;
    }
  }

  #marktinfoLight{
    .card {
      .card__content {
        padding-right: 5rem;
      }
    }
  }
}

// MARKETING CARD
.marketing-card {
  .card__content {
    padding: 0 1rem;
  }
  .marketing-card__title {
    display: flex;
    flex-direction: row;
    flex: 1;
    justify-content: flex-start;
    align-items: center;

    h3 {
      margin: 0;
      padding: 0;
      margin-right: 0.5rem;
    }

    svg {
      min-width: 2.8rem;
      width: 2.8rem;
      margin-right: 0.7rem;
    }
  }

  .textlink {
    display: inline-block;
    margin-bottom: 1rem;
  }

  .marketing-card__image {
    max-width: 100%;
    width: auto;
    margin-bottom: 1rem;
  }

  .marketing-card__date {
    font-size: 0.8rem;
  }

  @media (max-width: $mobile_break) {
    .card__content {
      padding-left: 0.5rem;
      padding-right: 0.5rem;
    }
    .marketing-card__title {
      svg {
        min-width: 2.5rem;
        width: 2.5rem;
      }
    }
  }
}

// BESICHTIGUNG CARD
.besichtigung-card {
  .besichtigung-card__title {
    display: flex;
    flex-direction: row;
    flex: 1;
    justify-content: flex-start;
    align-items: center;

    h3 {
      margin-bottom: 0;
      padding: 0;
      font-size: 1.1rem;
      color: $gray;
    }

    svg {
      margin-right: 1rem;

      background-color: $purple;
      border-radius: 50%;
      width: 3rem;
      height: 3rem;
      padding: 0.5rem;
    }
  }

  .besichtigung-card__date {
    margin-top: 0.2rem;
    font-size: 0.9rem;
  }

  p {
    line-height: 1.5;
  }

  h4 {
    font-size: 1rem;
    color: $gray;
    margin-bottom: 0;
  }

  ul {
    margin-top: 1.5rem;
  }
}

// IMMO-CARD
.immo-card {
  text-decoration: none !important;

  &.immo-card--inactive {
    filter: grayscale(1);
    opacity: 0.7;
  }

  img {
    height: 185px;
  }

  .topImage_icon {
    height: 185px;
    svg {
      transform: scale(3.5)
    }
  }

  .immo-card__headline-wrapper {
    min-height: 85px;
  }

  .immo-card__headline {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;

    a {
      text-decoration: none !important;
    }

    h3 {
      color: $erste-darkblue !important;
      overflow: hidden;
      margin-top: 0;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      word-break: normal;
      hyphens: auto;
    }

    .immo-card__favicon {
      margin-top: 3px;
      width: 22px;
      flex: 0 0 22px;
      height: auto;
      z-index: 999;
      cursor: pointer;
    }
  }

  .immo-card__teaser {
    display: flex;
    flex-direction: column;
    font-size: 0.9rem;
  }

  a {
    //margin: 0 auto;
    //display: table;
  }


  .immo-card__bottom {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    width: 100%;
    > div {
      display: flex;
      flex-direction: column;
      width: 49%;
      flex: 0 0 49%;
    }
    > div:nth-child(2) {
      text-align: right;
    }

    .immo-card__bottom--val {
      display: inline-block;
      font-weight: 600;
      line-height: 1;
      margin-top: 1.5rem;
      padding-bottom: 0.25rem;
      white-space: nowrap;
    }
  }
}

// IMMO-CARD IN CAROUSEL
.carousel {
  .immo-card {
    .topImage_icon {
      svg {
        transform: scale(1.1)
      }
    }
  }
}

.immoabgeber-card {
  a {
    text-decoration: none;
  }

  .immo-card__headline {
    flex-direction: column;
    p {
      color: $gray;
    }
  }

  &.immo-card__2col {
    > a,
    .card__content {
      flex: 0 0 42%;
      width: 42%;
    }

    img {
      width: 100%;
      height: 100%;
    }

    .topImage_icon {
      height: 390px;
      svg {
        transform: scale(3.5)
      }
    }
  }

  .immo-card__bottom {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    width: 100%;

    .immodetail__features,
    .immodetail__features-wrapper {
      width: 100%;
      flex: 0 0 100%;
    }

    .immodetail__features {
      flex-direction: row;
      justify-content: space-between;
      .immodetail__features-wrapper {
        .immodetail__features--val {
          font-size: 1.4rem;
        }
      } 
    }

    .immo-card__bottom__personaldImmo {
      flex-direction: column !important;
      flex: 0 0 30%;
      text-align: center !important;
      .immo-card__bottom--val--personal {
        font-size: 2rem;
      }
      .immo-card__bottom--label--personal {
        font-size: 0.9rem;
      }
    }

    .immo-card__bottom-wrapper {
      display: flex;
      @media (min-width: $desktop_break) {
        flex-direction: column;
      }
      flex-direction: row;
      width: 49%;
      flex: 0 0 49%;
    }

    .immo-card__bottom-wrapper:nth-child(2) {
      text-align: right;
    }

    .immo-card__bottom--val {
      color: $erste-darkblue !important;
      margin-bottom: 0;
      margin-top: 1rem;
      @media (min-width: $desktop_break) {
        display: inline-block;
        font-size: 1.4rem;
        font-weight: 600;
        line-height: 1;
        margin-top: 1.5rem;
        padding-bottom: 0.25rem;
      }
    }
  }

  .card__content {
    display: flex;
    flex-direction: column;
  }
  .card__price--content {
    color: $erste-darkblue;
    font-size: 1.7rem;
    font-weight: 700;
  }

  .button--blue {
    width: fit-content;
  }

  @media (max-width: $mobile_break) {
    .card__price--content {
      font-size: 1.3rem;
      font-weight: 600;
      padding-top: 1rem;
    }
  }
}

.immoabgeber-card--empty {
  background-color: #fff;
  &.card.topImage .topImage_icon {
    background-color: $lightturquoise;
    svg {
      max-height: 120px;
    }
  }
}

/* KONTAKT-CARD */
.contactagent-card {
  h3,
  p {
    margin-bottom: 0;
    width: fit-content;
  }

  .card__content {
    padding-top: calc(1.5rem + 10px);
  }

  .contactagent-card__image {
    margin-top: 2.5rem;
    margin-left: 1.5rem;
    width: 4rem;
    min-width: 4rem;
    height: 4rem;
    border-radius: 50%;
    object-fit: cover;
    margin-bottom: 0.5rem;
  }


  .icon-list {
    li {
      margin-bottom: 5px;
      font-weight: 600;
    }
    a {
      font-weight: 600;
    }
    a:hover {
      text-decoration: underline;
    }
    svg {
      width: 1.25rem;
      margin-right: 5px;
    }
  }

  .button-panel {
    margin-bottom: 0;
  }

  .button { 
    a {
      color: $erste-blue;
    }
    &:hover,
    &:active,
    &:focus {
      a {
        color: #fff;
      }
    }
  }
  .button--orange {
    display: inline-block;
  }
}

//Contact card alone
.card.contact-card, .card.immodriveempty-card {
  .card__icon {
    height: 7rem;
    svg {
      //transform: scale(1.1);
    }
  }

  .card__content {
    button {
      //transform: scale(1.2);
    }
    h2 {
      font-size: 1.5rem;
    }
    p { 
      text-align: center;
    }
  }
  .contact-card__buttons {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    justify-content: center;
  }
}

.card.recommendation-card {
  p {
    margin-bottom: 2.5rem;
  }
  .card__icon {
    height: 5.5rem;
    svg {
      height: 7rem;
    }
  }

  .card__content {
    button {
      //transform: scale(1.2);
    }
    h2 {
      font-size: 1.5rem;
    }
  }
}

.cardagent-card {
  li {
    display: flex;
    justify-content: left;
    align-items: center;
    gap: 0.5rem;
    margin: 0.25rem 0;
  }
  li:first-child {
    display: none;
  }
  .card__content {
    div:has(button) {
      text-align: left;
    }
  }
}

//Contact card as col element
.contact-card.elements2cols__col,
.contact-card {
  background-color: $green;
  color: #fff;

  .card__icon {
    padding-top: 2.5rem;
    //padding-left: 1.5rem;
    height: 9rem;
    svg {
      transform: scale(0.9);
      transform-origin: top left;
    }
  }

  .card__content { 
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

.marktbericht-card {
  background-color: $darkerturquoise;
  position: relative;
  min-height: 450px;

  .marktbericht-card__content {
    display: flex;
    flex-direction: row;
    z-index: 2;

    .marktbericht-card__text {
      width: 50%;
      padding: 2rem;
      padding-top: 0;
      z-index: 2;
    }

    .marktbericht-card__image {
      height: 450px;
      position: absolute;
      right: 0;
      bottom: 0;
      z-index: 1;
    }
  }

  .marktberichtForm {
    .form__field.w-60 {
      width: 58%;
      flex: 0 0 58%;
    }

    .form__field.w-40 {
      width: 38%;
      flex: 0 0 38%;
    }

    .marktbericht-error {
      .marktbericht-errorbox {
        width: 100%;
        margin-top: 1rem;
        background-color: $light-red;
        padding: 1.5rem;
        border-radius: 0.5rem;
        color: red;
        margin-top: 6rem;
        margin-bottom: 7rem;
      }
    }

    .marktbericht-errortext-notfound {
      position: absolute;
      bottom: 30px;
      left: 270px;
    }
  }
}

@media all and (max-width: $desktop_break) {
  .marktbericht-card {
    min-height: 200px;
    .marktbericht-card__content {
      display: flex;
      flex-direction: row;
      z-index: 2;

      .marktbericht-card__text {
        width: 100%;
      }

      .marktbericht-card__image {
        display: none;
      }
    }

    .marktberichtForm {
      .form__field {
        width: 100% !important;
        max-width: unset !important;
      }

      .marktbericht-error {
        .marktbericht-errorbox {
          margin-top: 0;
          margin-bottom: 2rem;
        }
      }

      .marktbericht-errortext-notfound {
        position: relative;
        bottom: unset;
        left: unset;
      }
    }
  }
}

.immodriveempty-card {
  background-color: $default-darkblue;

  .card__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      transform: scale(1);
      transform-origin: top left;
    }
  }
}

/* Icon Content Card */
.icon-content-card {
  background-color: white;
  box-shadow: none;
  p, h2 {
    color: #0B1F42;
  }
  h2 {
    margin: 2.5rem 0;
  }
  .icon-box-wrapper {
    display: flex;
    justify-content: space-around;
    margin: 3rem 0 1rem 0;
    flex-direction: column;
    align-items: center;
  }
}

.card--vorteile {
  .card__content {
    padding: 2em 2rem 2rem 2rem;
    .card__content__text {
      padding-top: 0.5rem;
      margin-bottom: 2rem;
    }
  }
}

/* IMMOLIVE-CARD */
.immolive-card {
  .immolive-card__content {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    margin-bottom: 1rem;

    span {
      font-size: 32pt;
      font-weight: bold;
    }
  }

  svg {
    height: auto;
    width: 6rem;
  }
}

/* SUCHPROFILE CARD */
.slick-slide:has(.suchprofil-card) {
  div {
    height: 100%;
    .suchprofil-card{
      height: 100%;
      .card__content {
        height: 100%;
        display: flex;
        flex-direction: column;
        div {
          height: unset;
        }
        .button-panel {
          margin-top: auto;
        }
      }
    }
  }
}
.suchprofil-card {
  width: 100%;

  h4 {
    margin-top: 1.5rem;
    margin-bottom: 0.75rem;
  }

  .image--round {
    position: relative;
    background-color: $erste-darkblue;
    min-height: 60px;
    min-width: 60px;

    svg {
      position: absolute;
      height: auto;
      transform: unset;
      left: 0.75rem;
      top: 0.75rem;
      width: 3rem;
    }
  }

  .suchprofil-card__data {
    padding-top: .5rem;
    margin-bottom: 2rem;

    h4 {
      font-weight: 600;
      padding: 0;
      margin: 0;
      margin-bottom: 0.25rem;
    }

    p {
      margin: 0;
    }
  }

  .button-panel {
    margin-bottom: 0;
  }
}

.w-desktop--slider {
  .suchprofil-card {
    width: auto;
  }
}

.news__other,
.news-card,
.news__maincards {
  .topImage {
    img {
      min-height: 185px !important;
      max-height: 185px !important;
    }
  }
}

.news-card {
  .card:hover {
    .card__readmorelink, .textlink {
      text-decoration: underline !important;
    }
  }
}  

.card__readmorelink {
  text-decoration: none;
  font-weight: 600;
	color: $erste-blue;
}

#sreal-kundenportal-root .gray-background:has(.news__maincards) {
  margin-top: 0;
  padding-top: 2.5rem;
   padding-bottom: 2.5rem;
}

.news__maincards {
  max-width: 1170px;
  margin: 0 auto;
  .card {
    margin-bottom: 0.5rem;
  }
}

.card-link:has(.card--highlight-active) {
  width: 66%;
  min-width: 66%;
  height: 29rem;
}

.card--highlight-active {
  height: 100%;
  p {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }
  img {
    height: 16.5rem;
  }
  .card__content {
    padding-top: 0.7rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 12.5rem;
  }
}

.card--highlight-active:hover {
  .card__readmorelink {
    text-decoration: underline;
  }
}

.news__highlight {
  flex-direction: column;
  width: 32%;
  min-width: 32%;
  max-width: 32%;
  gap: 1rem;
  justify-content: start;
  .news-card {
    min-height: unset;
    height: 5rem;
    width: 100%;
    .card { 
      min-height: unset;
      height: 5rem;
      img {
        height: 5rem;
        width: 4.5rem;
        object-fit: cover;
        margin-right: 1rem;
      }
      .card__content__text {
        margin-right: 1rem;
        height: fit-content;
        display: flex;
        flex-direction: column;
        align-self: center;
      }
      .cardMeta {
        margin-bottom: 0;
        font-size: 0.75rem;
      }
      p:not(.cardMeta) {
        font-size: 1rem;
        overflow: hidden;
        display: -webkit-box;
        margin-bottom: 0;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
      }
    }
  }
}

@media all and (min-width: $mobile_break) {
  .card.card--energy-desktop {
    .immodetail__features-wrapper {
      margin-bottom: 3rem;
    }
  }
  .card.recommendation-card {
    .card__icon {
      height: 6rem;
    }
    p {
      margin-bottom: 1rem;
    }
  }
  /* FINANZIERUNG CARD */
  .card--finanzierung {
    color: $erste-darkblue;
    h2 {    
      color: $erste-darkblue;
    }
    .wohnwertlight-form__content {
      padding: 0 !important;
    }
    .button-panel {
      .button {
        margin-right: 10px;
        width: unset;
      }
    }
    img {
      max-width: 40%;
    }
  }

  .card--vorteile {
    height: 22rem;
      .card__content {
        padding: 2em 2rem 1.5rem 2rem;
        .card__content__text {
          height: 12rem;
          margin-bottom: 0;
        }
      }
  }

  .card.contact-card,
  .card.recommendation-card {
    .card__content {
      h2 {
        font-size: 2.5rem;
      }
    }
  }

  .card.immodriveempty-card {
    .card__content {
      h2 {
        font-size: 2rem;
      }
    }
  }

  .suchprofil-card {
    min-width: 350px;
  }
  
  /* KONTAKT-CARD */
  .contactagent-card {

    .contactagent-card__image {
      width: 6rem;
      height: 6rem;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 1rem;
      svg {
        transform: scale(1.2);
        margin-top: 23%;
      }
    }
  }

  //FAQ
  .faq {
    .card {
      margin-top: 3rem;

      &.card-questions {
        h3 {
          font-size: 1.6rem;
        }
      }
    }
  }

  .immoabgeber-card--empty {
    .card__content {
      min-height: 350px;
      padding-top: 3rem;
    }
  }
  .immoabgeber-card.immo-card__2col {
    img {
      width: 30rem;
    }
  }
  .immoabgeber-card {
    .immo-card__bottom {
      .immodetail__features {
        .immodetail__features-wrapper {
          .immodetail__features--val {
            font-size: 1.4rem;
          }
        } 
      }
    }
  }
}

.card--landingpage {
  img {
    max-height: 24rem !important;
    object-fit: contain !important;
    padding: 2rem;
  }

  .card__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

.statistic-card {
  .statistic-card__header,
  .statistic-card__data {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
  }

  &.statistic-card--loading {
    .statistic-card__data {
      display: inline-block;
    }
  }

  .statistic-card__emptymsg {
    margin-bottom: 0;
    padding: 2rem;
    padding-top: 0;
  }

  .statistic-card__header {
    padding-bottom: 2rem;
    margin-bottom: 2rem;
    border-bottom: 1px solid $lightgray;

    h2 {
      padding: 1rem;
      padding-bottom: 0.5rem;
      box-sizing: border-box;
    }
  }

  .statistic-card__data {
    padding: 1rem;
    padding-top: 0;
    height: 100%;
  }

  .statistic-card__chart {
    position: relative;
    margin-bottom: 3rem;

    .statistic-card__linechart--count {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      font-weight: 700;
      font-size: 4rem;
      margin: 0;
      padding: 0;
    }
  }

  .statistic-card__chart-dashboard {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .statistic-card__linechart--count {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      font-weight: 700;
      font-size: 4rem;
      margin: 0;
      padding: 0;
    }

    a {
      color: #fff;
    }
  }

  .statistic-card__numberchart {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;

    p {
      display: flex;
      flex-direction: column;
      text-align: center;
      justify-content: center;
      width: 100%;
      margin: 0;
      padding-bottom: 1rem;
    }
  }
}

@media all and (min-width: $mobile_break) {
  .cardagent-card {
    li:first-child {
      display: flex;
    }
      li {
        justify-content: center;
      }
      .card__content {
        div:has(button) {
          text-align: center;
        }
      }
  }
  .infrastructure-header {
    flex-direction: row;
  }
  .card.card--infrastructure {
    h3 {
      text-align: left;
    }
    margin-left: unset;
    width: unset;
    border-radius: 12px !important;
    .infrastructure__standort {
      display: flex;
    } 
    .infrastructure__line--desktop {
      display: block;
    }
    .infrastructure__line--mobile {
      display: none;
    }
    .infrastructure__categories__circle-wrapper {
      width: 160px;
      flex-direction: column;
      p {
        order: 3;
        margin-bottom: 0;
        margin-left: 0rem;
      }
      span {
        order: 1;
        margin-left: 0rem;
      }
      .infrastructure__categories__circle {
        order: 2;
      }
    }
    .infrastructure__categories {
      flex-direction: row;
      gap: unset;
    }
  }
  .icon-content-card {
    background-color: $lightgray-backgrounds;
    .icon-content-card__subheadline {
      width: 70%;
      margin-left: auto;
      margin-right: auto;
    }
    .icon-box-wrapper {
      flex-direction: row;
      align-items: unset;
    }
  }
  .statistic-card {
    padding: 2rem;

    &.statistic-card--loading {
      .statistic-card__data {
        display: flex;
      }
    }

    .statistic-card__header,
    .statistic-card__data {
      flex-direction: row;
      border-bottom: 0;
    }

    .statistic-card__emptymsg {
      padding: 0;
    }

    .statistic-card__header {
      margin-bottom: 0;

      h2 {
        padding: 0;
      }
    }

    .statistic-card__data {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-items: center;
      padding: 0;

      .statistic-card__chart,
      .statistic-card__legend {
        position: relative;
        width: 45%;
        flex: 0 0 45%;
      }
    }

    .statistic-card__numberchart {
      height: 100%;
      flex-direction: column;
      p {
        display: flex;
        align-items: center;
        flex: 1;
      }
    }
  }
}

.statistic-card__chart-dashboard {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: space-between;

  &.clickchart {
    flex-direction: column;
  }

  .statistic-card__linechart--count {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    font-weight: 700;
    font-size: 4rem;
    margin: 0;
    padding: 0;
  }
}

@media all and (min-width: $desktop_break) {
  .card2.card--infrastructure {
    .infrastructure__line--desktop {
      width: 100%;
    }
  }
  .immoabgeber-card {
    .immo-card__bottom {
      .immodetail__features {
        .immodetail__features-wrapper {
          .immodetail__features--val {
            font-size: 1.7rem;
          }
        } 
      }
    }
  }
  .immoabgeber-card.immo-card__2col {
  > a,
  .card__content {
    flex: 0 0 50%;
    width: 50%;
  }
}
  .card.card--infrastructure {
    .infrastructure__line--desktop {
      width: 95%;
      right: 30px;
    }
  }
}

@media all and (max-width: 400px) {
  .header__content {
    .chart-wrapper--vertical {
      max-width: 45%;
    }
  }
}