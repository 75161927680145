@import '../../../../shared/styles/sreal_default/vars/colors.scss';
@import '../../../../shared/styles/sreal_default/vars/breaks.scss';

.quicklinks {
  border-bottom: 1px solid $lightgray;
  position: relative;
  align-items: center;
  background-color: white;
  color: $erste-blue;
  box-shadow: 0px 1px 1px 0px #0B1F420A;
  display: none;
  padding-left: calc((100% - $max_tablet_width) / 2);
  
  &.sticky {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000;
  }

  .quicklinks__item {
    position: relative;
    margin-right: 5rem;
    padding: 2rem 0;

    .quicklinks__link {
      font-weight: 600;
      text-decoration: none;
    }      

    .arrow {
      position: absolute;
      bottom: -5px;
      left: 45%;
      opacity: 0;
      transition: opacity 0.3s ease;
      width: 10px;
    }
  }

  .quicklinks__link.active + .arrow {
    opacity: 1;
  }
}

@media all and (min-width: $mobile_break) {
  .quicklinks {
    display: flex;
  }
}

@media all and (min-width: 1200px) {
  .quicklinks {
    padding-left: calc((100% - $max_desktop_width) / 2);
  }
}
  