@import '../../../../shared/styles/sreal_default/vars/colors.scss';

.dot-unread {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $red;
  width: 16px;
  min-width: 16px;
  height: 16px;
  border-radius: 50%;

  &.dot-unread--withNumber {
    width: 24px;
    height: 24px;
    min-width: 24px;
  }

  span {
    color: #fff;
  }
}

#header {
  .dot-unread {
    span {
      color: #fff !important;
    }
  }
}
