// FLEXBOX
.d-flex{
    display:flex;
}

.d-flex--btnandicon {
    align-items: end;
    justify-content: space-between;
    svg {
        margin-right: 2rem;
    }
}

.d-flex--gap {
    gap: 12px;
}

.d-flex--gap-5p {
    gap: 5%;
}

.d-flex--gap-0 {
    gap: 0;
}

/* flex direction */
.flex-row{
    flex-direction: row !important;
}
.flex-col{
    flex-direction: column;
}

/* justify content */ 
.justify-content-start{
    justify-content: start;
}

.justify-content-end{
    justify-content: end;
}

.justify-content-between{
    justify-content: space-between;
}

.justify-content-center {
    justify-content: center;
}

/* align items */ 
.align-items-center{
    align-items: center;
}

.align-items-start {
    align-items: flex-start;
}

.align-items-end{
    align-items: flex-end;
}

.align-items-baseline{
    align-items: baseline;
}

/* align content */
.align-content-center {
    align-content: center;
}

/* align self */
.align-self-center {
    align-self: center;
}

/* flex wrap */
.flex-nowrap {
    flex-wrap: nowrap;
}

.flex-wrap {
    flex-wrap: wrap;
}

.flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.flex-1 {
    flex: 1;
}