@import '../../../../shared/styles/sreal_default/vars/colors.scss';
@import '../../../../shared/styles/sreal_default/vars/breaks.scss';

.icon-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 12rem;
    margin: 0 auto;
    h5 {
        color: $text-blue;
    }
    p {
        color: $gray;
    }
    svg {
        height: 2.5rem;
        width: 2.5rem;
    }
    .icon-box__icon {
        width: 4rem;
        height: 4rem;
        border-radius: 0.4rem;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
    }
}  