@import '../../../../shared/styles/sreal_default/vars/colors.scss';
@import '../../../../shared/styles/sreal_default/vars/breaks.scss';

.sreal-footer {
  background-color: #0b1f42;
  width: 100%;
  color: #fff;
  font-size: 0.9rem;

  &__inner {
    padding: 1rem;
  }

  &__main-block {
    display: flex;
    flex-direction: row-reverse;
  }

  &__to-top-wrapper {
    display: inline-flex;
    margin-left: 5rem;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    margin-top: -1rem;
  }

  &__social {
    margin-bottom: 2rem;
    gap: 20px;
    a {
      cursor: pointer;
    }
  }

  &__blue-area {
    background-color: $erste-blue;
    text-align: center;
    padding: 1rem;
    border-radius: 5px;

    img {
      width: 12rem;
      padding-bottom: 0.5rem;
      margin-top: 0.5rem;
      cursor: pointer;
    }
  }

  .footer-blue-link-wrapper {
    margin-top: 1rem;
    text-align: center;
    .footer-blue-link {
      display: inline-block;
      margin: 1rem;
      color: #fff;
      text-decoration: none;
      cursor: pointer;
    }
  }

  .footer-bothlogos {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;

    img:first-child {
      margin-right: 1rem;
      max-width: 120px;
    }

    img:last-child {
      max-width: 145px;
    }
  }

  #btn--scrolltop {
    .btn-icon {
      background-image: url(../../../../shared/icons/arrow-top_white.svg);
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      padding-bottom: 1px;
      padding-right: 16px;
    }
  }
  &__copyright {
    text-align: center;
    color: #fff;
    margin-bottom: 2.5rem;
  }

  &__upper-menu {
    display: inline-flex;
    flex-grow: 1;
    justify-content: space-between;
    margin: 0rem 0;
  }

  &__upper-menu-block {
    font-weight: bold;
    cursor: pointer;
  }
}

.sreal-footer__upper-menu-block {
  position: relative;
  text-decoration: none;
  color: #fff;
  cursor: pointer;
  .header__nav__arrow {
    display: none;
  }
}

@media (max-width: $mobile_break) {
  .sreal-footer__main-block {
    display: flex;
    flex-direction: column;
  }
  .sreal-footer__to-top-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 0;
    margin-bottom: 1.5rem;
    margin-left: 0;
  }

  .footer-bothlogos {
    img {
      max-width: 110px !important;
    }
  }

  .sreal-footer__upper-menu {
    flex-direction: column;
  }
  .sreal-footer__upper-menu_content {
    display: none;
  }

  .sreal-footer__upper-menu-block {
    padding: 1rem 0;
    display: flex;
    justify-content: space-between;
    .header__nav__arrow {
      display: inline-block;
      margin-top: 5px;
    }
  }
  .footer-blue-link-wrapper {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
    .footer-blue-link {
      margin: 0.5rem !important;
    }
  }
}

@media all and (min-width: $mobile_break) {
  .sreal-footer__blue-area {
    margin: 0 auto;
    max-width: $max_tablet_width;
  }
  .sreal-footer {
    margin-top: 7rem;
  }
  .sreal-footer__upper-menu {
    max-width: 70%;
  }
}

@media all and (min-width: $desktop_break) {
  .sreal-footer__blue-area {
    max-width: $max_desktop_width;
  }
}
