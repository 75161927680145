@import '../../../../shared/styles/sreal_default/vars/colors.scss';
@import '../../../../shared/styles/sreal_default/vars/breaks.scss';

#header.header--slider {
	.slick-slider.carousel-slider {
		display: inline;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    padding-bottom: 0;

		.slick-track {
			height:100%;
			.slick-slide {
				margin: 0;

				.header__content-wrapper.w-100 {
					max-width: 100%;

					h1, h2 {
						width: 100%;
					}
				}
			}
		}

		.slick-dots {
			bottom: 0rem;

			li button {
				height: 15px;
				width: 15px;

				background-color: #023449;
				border-color: #023449;
				opacity: 0.4;
			}
		}

		li.slick-active button {
			background-color: #023449;
			border-color: #023449;
			opacity: 1;
		}
	}	
}

/* tablet + desktop styles */
@media all and (min-width: $mobile_break) {
	#header.header--slider {
		.slick-slider.carousel-slider {
			.slick-track {
				.slick-slide {
					.header__content-wrapper.w-100 {
						max-width: 600px;
						padding-right: 0;
						padding-left: 0;
					}
				}
			}

			.slick-dots {
				bottom: 2rem;
			}
		}
	}
}


@media all and (min-width: $mobile_break) and (max-width: $desktop_break) {
	#header.header--slider {
		.slick-slider.carousel-slider {
			.slick-track {
				.slick-slide {
					.header__content-wrapper.w-100 {
						left: 3.2rem !important;
					}
				}
			}
		}
	}
}
