@import '../../../../shared/styles/sreal_default/vars/colors.scss';
@import '../../../../shared/styles/sreal_default/vars/breaks.scss';

.header__hamburger {
  &.active {
    .hamburger-line {
      border-top: 4px solid $erste-darkblue;
    }
  }
  &:hover {
    cursor: pointer;
  }

  .hamburger-line {
    display: block;
    width: 30px;
    border-top: 4px solid #fff;
    border-radius: 7px;
    transition: 0.5s;
    transform-origin: center;

    &:nth-of-type(2),
    &:nth-of-type(3) {
      margin-top: 5px;
    }
  }

  &.active .hamburger-line:nth-child(1) {
    transform: translateY(9px) rotate(-45deg);
  }
  &.active .hamburger-line:nth-child(2) {
    opacity: 0;
  }
  &.active .hamburger-line:nth-child(3) {
    transform: translateY(-9px) rotate(45deg);
  }
}

@media (min-width: $desktop_break) {
	.header__hamburger {
		display: none;
	}
}