@import '../../../../shared/styles/sreal_default/vars/breaks.scss';
@import '../../../../shared/styles/sreal_default/vars/colors.scss';

.subunits {
	.subunits-filter {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		align-items: baseline;
		gap: 0.5rem;
		.form__field {
			margin-bottom: 0;
		}
	}
	.subunits__link {
		border-top: 1px solid $lightgray;
		padding-top: 1.5rem;
		padding-bottom: 1.5rem;
	}
	.subunits__immos {
		margin-top: 2rem;
		width: 100%;
		border-collapse: collapse;
		table-layout: fixed;
		flex-direction: column;
		border-radius: 4px;
		box-shadow: rgba(11, 32, 66, 0.1) 0px 4px 14px 2px;
		border-collapse: separate;
		border-spacing: 0;

		thead {
			td {
				font-weight: 700;
			}
		}

		td {
			width: calc(100% / 6);
			padding: 1rem;
			white-space: nowrap;
			align-content: center;
			
			img {
				height: 2.5rem;
				width: auto;
				object-fit: cover;
				transition: 0.5s scale ease;
				&:hover {
					scale: 8;
					border: 1px solid $lightgray;
					border-radius: 2px;
				}
			}
		}

		tr:nth-child(even) {
			background-color: $lightmidgray;
		}

		.subunits__immos-toprow {
			width: 100%;
			position: relative;
			padding-right: calc(50px + 0.5rem);

			img {
				position: absolute;
				top: 0;
				right: 0;
			}
		}
	}
}

@media all and (min-width: $mobile-break) {
	.subunits {
		.subunits-filter {
			gap: 1.5rem;
			.form__field {
				margin-bottom: 20px;
			}
		}
		.subunits__immos {
			margin-top: 0;
			td {
				padding: 1rem 2rem;
			}
		}
		.subunits__link {
			border-top: none;
			padding-top: 0;
			padding-bottom: 0;
		}
	}
}




