@import '../../../../shared/styles/sreal_default/vars/breaks.scss';

.immodetail__features {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: flex-start;
  gap: 1rem;
  flex-wrap: wrap;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;

  > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 0 0 auto;
    width: auto;

    .immodetail__features--val {
      font-weight: 600;
      font-size: 1.2rem;
      margin-bottom: 0.25rem;
      text-align: center;
    }

    .immodetail__features--label {
      font-size: 0.9rem;
    }
  }
}

.immo-card__bottom {
  .immodetail__features {
    width: 100%;
    justify-content: center;

    .immodetail__features-wrapper {
      width: 30%;
      flex: 1 0 30%;

      .immodetail__features--val {
        font-size: 1.7rem;
      }
    }
  }
}

@media all and (min-width: $mobile_break) {
  .immodetail__features > div {
		flex: 0 0 30%;
    width: 30%;

    .immodetail__features--val {
      font-size: 2rem;
      margin-bottom: 0.75rem;
    }
  }
}
