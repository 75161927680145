@import '../../../../shared/styles/sreal_default/vars/breaks.scss';
@import '../../../../shared/styles/sreal_default/vars/colors.scss';

.iconlist {
  display: flex;
  flex-flow: column;
  width: 100%;
  flex: 0 0 100%;

  &.iconlist--withimagebanner {
    margin-top: -120px;
  }

  .iconlist-element {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-bottom: 2rem;
    box-sizing: border-box;
    border-radius: 5px;
    z-index: 5;

    &__content {
      color: $erste-darkblue;
    }

    &.iconlist-element--highlightborder {
      display: inline-block;
      background-color: #fff;
      border: 1px solid $lightgray;
      text-align: left;
      padding: 1rem;

      .image--round {
        float: left;
        background-color: transparent;
      }
    }

    &.iconlist-element--highlightblue {
      display: inline-block;
      background-color: $erste-blue;
      color: #fff;
      padding: 20px;

      h2,
      h3 {
        color: #fff;
      }

      .image--round {
        float: left;
        background-color: transparent;
      }

      .iconlist-element__content {
        h2,
        h3,
        h4,
        h5,
        p,
        span {
          color: #fff;
        }

        > p {
          clear: both;
        }
      }
    }

    .image--round {
      width: 40px;
      height: 40px;

      margin-right: 1rem;
      margin-bottom: 1rem;
      background-color: #fff;

      svg {
        width: 40px;
        height: fit-content;
        max-height: 100%;
      }
    }

    .image--blue {
      background-color: $erste-darkblue;
      width: 66px;
      height: 66px;
      display: flex;
      align-items: center;
      svg {
        margin-left: 16px;
        width: 34px;
        max-height: 34px;
      }
    }
    .image--blue--mobile {
      background-color: $erste-darkblue;
      width: 80px;
      height: 62px;
      display: flex;
      align-items: center;
      svg {
        width: 62px;
        max-height: 34px;
      }
    }

    .iconlist-element__content {
      h2,
      h3,
      h4,
      h5 {
        margin-top: 0;
        margin-bottom: 1rem;
        padding-top: 0;
      }

      p {
        line-height: 1.5;
      }
    }
  }
}

//in dialog
.pagedialog,
.infodialog {
  .iconlist {
    flex-direction: column;
    h3 {
      font-size: 1.3rem;
    }
    .iconlist-element {
      width: 100%;
      flex-direction: row;
    }
    svg {
      width: 35px;
      padding-bottom: 17px;
    }
  }
}

@media (min-width: $mobile_break) {
  .iconlist {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    max-width: $max_tablet_width;
    margin: 0 auto;

    &.iconlist--withimagebanner {
      .iconlist-element {
        margin-right: 0;
        margin-left: 0;
        display: flex;
        flex: 0 0 32% !important;
        width: 32% !important;
        .image--round {
          margin: 0.5rem auto;
        }
        .iconlist-element__content {
          position: relative;
          min-height: 230px;
          width: 100%;
          text-align: center;
          h3 {
            margin-top: 1.5rem;
          }
          .iconlist__teaser {
            position: absolute;
            top: 4.5rem;
          }
        }
        .textlink {
          display: none;
        }
        svg {
          height: 100%;
        }
      }
    }

    .iconlist-element {
      flex-direction: column;
      align-items: flex-start;
      flex: 0 0 28%;
      width: 28%;

      h2,
      h3,
      h4,
      h5,
      p {
        margin-top: 1rem;
      }

      p {
        line-height: 1.5;
      }

      &.iconlist-element--highlightblue {
        background-color: #fff;
        border: 1px solid $lightgray;
        color: $gray;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;

        .iconlist-element__content {
          padding: 0 10px;
        }
      }
    }
    .iconlist-element__imagebanner-left {
      scale: 1.2;
      margin-top: 4px;
    }
    .iconlist-element__imagebanner-right {
      scale: 1.5;
      margin-top: 3px;
    }
  }
}

@media (min-width: $desktop_break) {
  .iconlist {
    max-width: $max_desktop_width;
  }
}
