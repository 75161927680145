@import '../../../../shared/styles/sreal_default/vars/colors.scss';
@import '../../../../shared/styles/sreal_default/vars/breaks.scss';

.finanzrechner__formresult {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: flex-end;
  justify-content: space-between;
  line-height: 1;
  margin-bottom: 1rem;
}

#immodetail .finanzrechner-form__content {
  h2 {
    margin-top: 0;
    text-align: left;
  }
}

.finanzrechner-form {
  // position: relative;
  // height: 60%;
  .finanzrechner-form__content {
    padding: 1rem;
    box-sizing: border-box;
    margin-left: -1.5rem;
    padding-left: 1.5rem;
    margin-right: -1.5rem;
    padding-right: 1.5rem;
    margin-bottom: -1.5rem;
    display: flex;
    flex-direction: column;
    .textlink {
      order: 3;
    }
    

    .finanzrechner-form__content__results {
      div.d-flex {
        flex-direction: column;
      }
    }

    h2, h3, label, p, span, a {
      color: $erste-darkblue;
    }

    .form__fielderror {
      color: $erste-red;
    }

    label {
      color: $gray;
      font-weight: 600;
      font-family: Inter;
      font-size: 1rem;
    }

    .form__row--double:last-child {
      margin-bottom: 0;
    }
  }

  .loading-info{
    color: #fff;
  }
}

@media all and (min-width: 400px) and (max-width: $mobile_break){
  .finanzrechner-form .finanzrechner-form__content{
    .finanzrechner-form__content__results div.d-flex {
      flex-direction: column;
      align-items: baseline;
    }
  }
}

@media all and (min-width: $mobile_break) and (max-width: $desktop_break) {

  .finanzrechner-form__content {
    .form__row {
      gap: 20px;
      .form__field {
        flex: 0 0 100% !important;
      }
    }
  }
}

@media all and (min-width: $mobile_break) {
  .finanzrechner-form {
  .finanzrechner-form__content {
    display: block;
    padding-left: 0rem !important;
    padding-right: 0rem !important;
    margin-left: 0;
    margin-right: 0;
    .finanzrechner-form__content__results {
      display: flex;
      gap: 40px;
      .d-flex {
        flex-direction: column;
        align-items: start;
        order: 1;
      }
      p {
        margin-bottom: 0;
      }
    }
  }
}
}