@import '../../../../shared/styles/sreal_default/vars/colors.scss';
@import '../../../../shared/styles/sreal_default/vars/breaks.scss';

.filtermenu {
    display: inline-block;
    border-radius: 1rem;
    
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    // overflow: scroll;
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
    transition: all 0.2s;

    will-change: transform;
    user-select: none;
    cursor: pointer;
      

    &.active {
        background: rgba(255,255,255,0.3);
        cursor: grabbing;
        cursor: -webkit-grabbing;
    }


    &.filtermenu--inverted {
        .filtermenu__item {
            color: #fff;
            
            &.active {
                color: $erste-blue;
                background-color: #fff;
            }
        }
    }

    &.filtermenu--inverted.grey {
        .filtermenu__item {
            &.active {
                color: $lightstone;
                background-color: #fff;
            }
        }
    }

    &.text-green {
        .filtermenu__item {
            &.active {
                color: $green;
            }
        }
    }

    &.text-purple {
        .filtermenu__item {
            &.active {
                color: $purple;
            }
        }
    }

    &.filtermenu--withoutbg {
        background-color: transparent;
    }
    
    .filtermenu__item {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;

        color: $erste-blue;
        padding: 0.3rem 0.5rem;
        margin-right: 0.5rem;
        box-sizing: border-box;
        white-space: pre;
        cursor: pointer;

        &.active {
            color: #fff;
            background-color: $erste-blue;
            border-radius: 1rem;
            transition: background-color 0.6s ease;
        }

        &:last-child {
            margin-right: 0;
        }

        .dot-unread {
            margin-left: 0.5rem;
        }
    }
}

@media all and (max-width: $mobile_break) {
    /* cut at right side */
    .filtermenu-wrapper-right {
        margin-left: 2rem;
        width: calc(100% - 1rem);

        .filtermenu {
            padding-right: 1rem;
            border-bottom-right-radius: 0;
            border-top-right-radius: 0;
        }
    }

    /* cut at left side */
    .filtermenu-wrapper-left {
        margin-right: 2rem;
        width: calc(100% - 1rem);

        .filtermenu {
            padding-left: 1rem;
            border-bottom-left-radius: 0;
            border-top-left-radius: 0;
        }
    }

    .filtermenu__item.hasPadding {            
        &:last-child {
            padding-right: 2rem;
        }
    }

    #marktinfoLight, .pagedialog {
        .filtermenu-wrapper-right {
            margin-left: 1rem;
        }
        .filtermenu-wrapper-left {
            margin-left: 1rem;
        }
    }
    #marktinfoLight {
        .filtermenu-wrapper-left {
            margin-left: -2rem;
            width: calc(100% + 2rem);
            .filtermenu {
                padding-left: 2rem;
            }
        }
    }
    .news {
        .filtermenu-wrapper-right {
            margin-left: 0rem;
            width: calc(100% + 2rem);
            margin-top: -2rem;
            margin-bottom: 2rem;
        }
    }
}

.filtermenu-wrapper-none {
    // TBA if needed
}