ul{
  &.list-style-none{
    list-style: none;
  }

	&.list--withcheckmark {
		list-style: none;
		li {
			background-image: url(../../../icons/checkmark_circle_white.svg);
      background-repeat: no-repeat;
      background-size: 1em;
			background-position: left 3px;
      padding-left: 1.7em;
			line-height: 1.5;
		}
	}
}

