@import '../../../../shared/styles/sreal_default/vars/colors.scss';
@import '../../../../shared/styles/sreal_default/vars/breaks.scss';

nav {
  display: none;
  background-color: #fff;

  &.is-visible {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    position: fixed;
    top: 0;
    right: 0;
    width: 90%;
    height: 100%;
    padding: 1.75rem;
    padding-top: 1.85rem;
    z-index: 9999;
    color: $erste-darkblue;
    background-color: #fff;
    line-height: 1.6;
    overflow: scroll;

    &::before {
      content: '';
      filter: blur(5px);
    }

    .image--round {
      background-color: $erste-blue;
      position: relative;
    }

    .textlink {
      color: $erste-blue;
    }

    span,
    p:not(.form__fielderror) {
      color: $erste-darkblue;
    }

    .form__fielderror {
    }

    .button--blue span {
      color: #fff;
    }
  }
}
.flyout {
  .header__nav {
    flex-direction: column;
    gap: 0px;
    li:hover {
      border-bottom-color: transparent !important;
      background-color: transparent;
    }
    .active {
      background-color: transparent;
    }
  }
  .textlink {
    color: $erste-blue !important;
  }
}
nav,
.flyout {
  .header__nav {
    margin: 0;
    padding: 0;
    list-style-type: none;

    .header__nav__link {
      display: inline-block;
      width: 100%;
      text-decoration: none;

      &.header__nav__link--withsubmenu {
        > .header__nav__span {
          background-image: none;
        }
      }
    }

    .header__nav__span {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;
      width: 100%;
      background-position: 100% center;
      background-image: url(../../../../shared/icons/arrow_blue_right.svg);
      background-repeat: no-repeat;
      background-size: 12px;
      font-weight: 700;
      list-style-type: none;
      color: $erste-darkblue;

      .dot-unread {
        margin-left: 0.25rem;
        span {
          color: #fff !important;
        }
      }
    }

    .header__nav__sub {
      margin-top: 0.5rem;
      margin-left: 2rem;
      list-style-type: none;
    }

    li {
      margin: 0;
      padding: 0;
      padding: 0.5rem 0;
      width: 100%;
      color: $erste-darkblue;
    }

    a {
      display: inline-block;
      color: $erste-darkblue;
      text-decoration: none;
      font-weight: 600;
    }
  }

  .header__nav--mobiletop {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex: 0 0 100%;
    width: 100%;
    max-height: 40px;
    margin-bottom: 1rem;

    img {
      height: 30px;
      margin-top: 5px;
    }
  }
}

@media (min-width: $desktop_break) {
  .header__nav {
    display: flex;
    list-style: none;
    margin-top: 1rem;
    gap: 1rem;

    &.header__nav--desktop {
      li.header__nav--desktophidden {
        display: none;
      }
    }

    .header__nav__arrow {
      margin-left: 4px;
    }

    li {
      color: $erste-darkblue !important;
      font-weight: 600;

      .header__nav__span {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: flex-start;
        cursor: pointer;
        color: #fff;
        font-weight: 600;
        min-height: 24px;
        white-space: nowrap;
        

        .dot-unread {
          margin-left: 0.25rem;
          background-color: $red;
        }
      }

      .header__nav__link--withsubmenu {
        > .header__nav__span {
          display: inline-block;
          cursor: default;
        }
      }

      li {
        list-style: none;
        .header__nav__span {
          display: none;
        }
      }
    }
    .active:has(.header__nav__link--withsubmenu) {
      background-color: transparent;
    }
    .active {
      background-color: rgba(9, 24, 50, .12);
      border-radius: 0.6rem;
    }
    .active .header__nav__sub li {
      background-color: transparent;
    }
    li:hover {
      background-color: rgba(9, 24, 50, .12);
      border-radius: 0.6rem;
    }
    li:has(.header__nav__sub):hover {
      background-color: transparent;
    }
  }
  li:hover {
    .header__nav__arrow {
      rotate: (-180deg);
      transition: 0.5s;
    }
    .header__nav__sub {
      background-color: white;
      position: absolute;
      border-radius: 0.4rem;
      border: 1px solid $dropdownborder;
      margin-top: 7px;
      margin-left: -12px;
      padding: 0.5rem 12px;
      .header__nav__span {
        display: inline-block;
        padding-bottom: 3px;
      }

      .header__nav__sub {
        li.active {
          .header__nav__span {
            font-weight: 600;
          }
        }
      }
    }

    .header__nav__sub {
      margin-top: 0.5rem;
      list-style-type: none;
      padding-top: 17px;

      .header__nav__span {
        display: inline-block;
        width: 100%;
        margin-bottom: 12px;
        padding-right: 3rem;
        background-position: 100% center;
        background-image: url(../../../../shared/icons/arrow_blue_right.svg);
        background-repeat: no-repeat;
        background-size: 7px;
        list-style-type: none;
        color: $erste-darkblue;
        white-space: pre-wrap;

        .dot-unread {
          display: inline-flex;
        }
      }
    }
  }
}

@media (min-width: $desktop_break) and (max-width: 1300px) {
  .header__nav {
    margin-top: 1.5rem;
    margin-bottom: -0.5rem;
  }
}

@media all and (max-width: $desktop_break) {
  .header__nav.header__nav--desktop {
    display: none;
  }

  .header__nav__span {
    svg {
      display: none;
    }
  }

  nav {
    .header__nav {
      .header__nav__link--withsubmenu {
        > .header__nav__span:nth-child(2) {
          display: none;
        }
      }

      .header__nav__sub {
        .header__nav__span {
          font-weight: 400;
        }

        li.active {
          .header__nav__span {
            font-weight: 600;
          }
        }
      }
    }
  }
}
