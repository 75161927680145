@import '../../../../shared/styles/sreal_default/vars/colors.scss';
@import '../../../../shared/styles/sreal_default/vars/breaks.scss';

.header--news {
  position: relative;
  background-color: $newblue;
  height: auto;
  
  &.header--withimage {
    max-height: 75vh;
  }
}

#header.header--news.header--darkbluebg {
	background-color: $newblue;
}

.header--newsdetail {
  .header__desktopheadlines {
    max-width: 720px;
    flex-wrap: wrap;

    h1 {
      margin-bottom: 1rem;
    }

    .header--news__sublines {
      display: inline-block;
      width: 100%;
      margin-bottom: 2rem;
    }
  }
}

.header--news__links {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  flex: 0 0 calc(100% - 2rem);
  width: calc(100% - 2rem);
  margin: 1rem;

  .textlink {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.9rem;

    svg {
      width: auto;
      height: 16px;
      margin-right: 0.5rem;
    }
  }
}

.newsdetail {
  color: $erste-darkblue;
  overflow-wrap: break-word;
  height: 50vh;
  position: relative;
  max-width: calc(975px + 6.4rem) !important;
  padding: 0 3.2rem;
}

.news__highlight-wrapper {
  margin-top: -1px;

	.card-link {
		text-decoration: none !important;
	}
}

.newsdetail__content {
  figure {
    width: 100%;
    img {
      max-width: 100%
    }

    iframe {
      width: 100%;
    }

    figcaption {
      text-align: center;
      font-size: 0.8rem;
      color: $erste-darkblue;
    }
  }

  ol, ul {
    list-style-type: decimal;
    margin-left: 3rem;

    li {
      margin-top: 0.5rem;
    }
  }
}

@media (min-width: $mobile_break) {
  .header--news {
    .header--news__links {
      display: none;
    }
  }

  .newsdetail {
    .header--news__links {
      display: flex;
      position: static;
      margin: 0;
      margin-bottom: 2rem;
      width: 100%;

      .textlink {
        color: $erste-blue;
      }
    }
  }
}

@media (min-width: $desktop_break) {
  .header--news {
    height: 15rem !important;
    h1 {
      margin-bottom: 0;
    }
  }
}