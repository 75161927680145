/* Inter Font */
/* inter-regular - latin */
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  src: local("Inter Regular"), local("Inter-Regular"),
    url("../../../fonts/inter/Inter-Regular.ttf") format("truetype"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("../../../fonts/inter/Inter-Regular.ttf")
      format("ttf");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  unicode-range: U+000-5FF; /* Latin glyphs */
}

/* inter-600 - latin */
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  src: local("Inter Light"), local("Inter-Light"),
    url("../../../fonts/inter/Inter-Light.ttf") format("truetype"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("../../../fonts/inter/Inter-Light.ttf") format("truetype");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  unicode-range: U+000-5FF; /* Latin glyphs */
}

/* inter-600 - latin */
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  src: local("Inter SemiBold"), local("Inter-SemiBold"),
    url("../../../fonts/inter/Inter-SemiBold.ttf") format("truetype"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("../../../fonts/inter/Inter-SemiBold.ttf") format("truetype");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  unicode-range: U+000-5FF; /* Latin glyphs */
}

/* Inter-700 - latin */
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  src: local("Inter Bold"), local("Inter-Bold"),
    url("../../../fonts/inter/Inter-Bold.ttf") format("truetype"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */
      url("../../../fonts/inter/Inter-Bold.ttf") format("truetype");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  unicode-range: U+000-5FF; /* Latin glyphs */
}
