@import '../vars/breaks.scss';

.w-100 { width: 100% !important; }
.w-90 { width: 90%; }
.w-75 { width: 75%; }
.w-60 { width: 60%; }
.w-50 { width: 50%; }
.w-30 { width: 30%; }
.w-25 { width: 25%; }

.w-3rem { width: 3rem; }

.w-50--desktop { width: 100%; }
.w-fit-content { width: fit-content; }

.f-100 { flex: 0 0 100%; }
.f-75 { flex: 0 0 75%; }
.f-50 { flex: 0 0 50%; }
.f-30 { flex: 0 0 30%; }
.f-25 { flex: 0 0 25%; }

.w-desktop--center {
    max-width: $max_desktop_width;
    margin-left: auto;
    margin-right: auto;
}

.w-tablet--center {
    max-width: $max_tablet_width;
    margin-left: auto;
    margin-right: auto;
}

.w-desktop--slider {
    @media all and (min-width: $mobile_break) {
        --widthcalc: calc(100% - 900px);
    }

    @media all and (min-width: $desktop_break) {
        --widthcalc: calc(100% - 1170px);
    }
}

@media all and (min-width: $mobile_break) {
    .w-desktop--slider {
        margin-left: 0;
        margin-bottom: 0;
        padding-bottom: 3rem;
        .slick-track {
            padding-left: calc(var(--widthcalc) / 2);
            padding-right: calc(var(--widthcalc) / 2)
        }
        .w-desktop--slider__headline { 
            margin-left: calc(var(--widthcalc) / 2);
            margin-bottom: 1rem;
       }
    }

    .w-50--desktop { width: 50%; }

}