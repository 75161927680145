@import '../../../../shared/styles/sreal_default/vars/colors.scss';
@import '../../../../shared/styles/sreal_default/vars/breaks.scss';

.progressbar__data-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 2rem;
  width: 100%;

  .progressbar__value-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 0 0 50%;
    width: 50%;

    .progressbar__value {
      font-size: 2rem;
      font-weight: 600;
    }

    .progressbar__valuelabel {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      margin-top: 0.25rem;

      svg {
        margin-right: 0.25rem;
        width: 18px;
        height: auto;
      }

      span {
        font-weight: 200;
        font-size: 0.9rem;
      }
    }
  }
}

.progressbar__bar {
  height: 12px;
  margin-top: 3rem;
  background-color: $lightgray;

  .progressbar__bar--filled {
    height: 100%;
    background-color: $erste-blue;
  }

  &.progressbar--blue {
    background-color: rgba(255, 255, 255, 0.5);
    .progressbar__bar--filled {
      background-color: white;
    }
  }
}