@import '../../../../shared/styles/sreal_default/vars/breaks.scss';
@import '../../../../shared/styles/sreal_default/vars/colors.scss';

.cookiesetting {
    .cookiesetting__text, .cookiesetting__btn {
        flex: 0 0 100%;
        width: 100%;
    }

    .cookiesetting__text {
        display: flex;
        flex-direction: column;
        margin-top: 1rem;
        
        h3 {
            padding-top: 0;
            color: #fff;
        }
    }
    
    .cookiesetting__btn {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 1rem;

        .button {
            margin-left: 0;
            background-color: transparent;
            
            border: 1px solid white;
            color: white;

            &:first-child {
                border-bottom-right-radius: 0;
                border-top-right-radius: 0;
            }

            &:last-child {
                border-bottom-left-radius: 0;
                border-top-left-radius: 0;
            }

            &.active {
                background-color: #fff;
                border: 1px solid #fff;
                color: $erste-blue;
            }
        }
    }

    .cookiesetting__btn--required {
        .button {
            border-radius: 4px !important;
        }
    }
}

@media all and (min-width: $mobile_break) {
    .cookiesetting {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: nowrap;
        
        .cookiesetting__text {
            max-width: calc(100% - 150px);
        }
        .cookiesetting__text, .cookiesetting__btn {
            flex: 0 0 auto;
            width: auto;
        }
    }
}