@import '../../../../shared/styles/sreal_default/vars/colors.scss';
@import '../../../../shared/styles/sreal_default/sreal_default_styles.scss';

#immodetail {
  color: $erste-darkblue;

  .immo-subheader--no-images {
    background-color: $green;
    color: #fff;
    padding-left: 2rem;
    padding-right: 2rem;
    margin-top: -4rem;

    h1 {
      color: #fff;
    }
  }

  #documents-headline {
      padding-top: 2rem;
      text-align: center;
  }
  .documents-subheadline {
    font-size: 1rem;
    margin-top: 1rem;
    text-align: center;
  }

  .first-half-elements, .second-half-elements, .expose-element {
    .linkitem {
      width: 100% !important;
    }
  }
  
  h2 {
    font-size: 1.4rem;
    margin-top: 2rem;
    margin-bottom: 0.75rem;
    text-align: center;
  }

  h3 {
    text-align: center;
    font-size: 1.2rem;
  }

  .immodetail__features-wrapper {
    border-radius: 8px;
    padding: 1rem;
    max-width: fit-content;
    .immodetail__features--val {
      font-size: 1.2rem;
      margin-bottom: 0.2rem;
			white-space: pre;
    }
  }

  .immodetail__headline {
    // display: flex;
    padding-right: 25px;
    position: relative;
    hyphens: auto;
    @media all and (min-width: $mobile_break) {
      flex-direction: row;
      margin-top: 2rem;
    }

    h1 {
      margin: 0;
      font-size: 1.7rem;
    }
  }

  .immodetail--immolive {
    width: 80px;
    height: auto;
    margin-top: 1rem;
  }

  .immodetail__objectnumber {
    display: inline-block;
    width: 100%;
    margin-top: 1.5rem;
    margin-bottom: 1rem;
  }

  .w-desktop--center {
    padding-top: 0;
  }

  .immodetail__list {
    li {
      width: 100%;
    }
  }

  .immodetail__favicon {
    position: absolute;
    top: 5px;
    right: 0;
    width: 25px;
    float: left;
    cursor: pointer;

    svg {
      width: 100%;
      height: auto;
    }
  }

  .immodetail--fixed-wrapper {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
		max-width: 100vw;
    background-color: $erste-blue;
    z-index: 9999;

    .immodetail--fixed-wrapper__background-image {
      position: absolute;
      width: 100%;
      z-index: 0;
      min-height: 100%;
    }

    .immodetail--fixed {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 1rem;

      .immodetail--fixed__text {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: 100%;
        gap: 5%;
        align-items: center;
        margin-bottom: 0.5rem;
        h2 {
          width: 100%
        }
      }

      button, .button {
        white-space: nowrap;
        margin-top: 0;
      }

      h2 {
        font-size: 0.9rem;
        font-weight: 400;
        margin: 0;
        text-align: left;
      }

      .immodetail__price {
        font-weight: 600;
        font-size: 1.3rem;
      }
    }
    .immodetail--fixed--webangebote {
      flex-direction: column;
      span, h4 {
        font-size: 0.9rem;
      }
      .card {
        width: 100%;
        flex-direction: column;
        align-items: start;
        gap: 0.5rem;
        svg {
          display: none;
        }
      }
    } 
  }
  .immodetail--objectdetails {
    display: flex;
    flex-direction: column;
    .immodetail__detailbuttons {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
    }
  }
  .immodetail__location--address {
    position: absolute;
    z-index: 999;
    background-color: $lightstone;
    color: white;
    padding: 1rem;
    border-radius: 12px;
    margin-top: 1rem;
    margin-left: 1rem;
  }
  .w-desktop--center.immodetail__location-wrapper {
    padding-bottom: 0rem;
  }
  .immodetail__desc {
    h2 {
      text-align: left;
    }
    p {
      line-height: 1.4;
      margin-bottom: 5px;
    }
    ul {
      margin-left: 1rem;
      margin-bottom: 0.5rem;
    }
    li {
      margin-bottom: 0.5rem;
    }
    span
    :not(.linkitem__headline)
    :not(.linkitem__text)
    :not(.textlink) {
      background-color: $verylightgray !important;
    }

    .immodetail__location.immodetail__location--withgooglemaps {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
      flex-wrap: wrap;

      .immodetail__location--address,
      .maps-container-wrapper {
        display: flex;
        flex: 0 0 100%;
        width: 100%;
        min-height: 220px;
      }

      .immodetail__location--address {
        flex-direction: column;
        min-height: auto;
        margin-bottom: 2rem;

        span {
          background-color: #fff !important;
        }
      }

      .maps-container-wrapper {
        width: 100%;
        border-radius: 5px;
        background-color: $lightgray;
        position: relative;
        border-radius: 5px;

        .maps-container {
          width: 100%;
          border-radius: 5px;
        }

        a,
        .gmnoprint,
        .gm-control-active,
        img[alt='Google'] {
          display: none;
        }
      }
    }
  }

  .w-desktop--slider {
    @include device('mobile') {
      padding: 0;
    }
  }

  .elements2cols__col {
    margin-bottom: 0;
  }

  .immodetail__table {
    > table {
      flex-wrap: wrap;
    }
    display: flex;
    flex-direction: column;
  }

  .immodetail__price-agent--wrapper {
    display: flex;
    flex-direction: column;
    padding-top: 2rem;
    > div {
      flex: 50%;
    }
    .immodetail__price--wrapper {
      display: flex;
      flex-direction: column;
    }

    .contactagent-card {
      margin-top: 4rem;
    }
  }
  .desktop--box {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;

    .desktop--box_item {
      flex: 0 0 49%;
      margin-bottom: 2rem !important;
    }
  }

  .immodetail__icons-mobile {
    margin-top: 10px;
    display: flex;

    svg {
      margin-right: 0.5rem;
    }
  }

  .popup--share-wrapper {
    position: relative;
    margin-top: 0px;
  }
  
  .popup--share {
    position: absolute;
    width: 150px;
    top: 110%;
    left: 240%;
    transform: translateX(-50%);
    background: #e9eaf0;
    color: $erste-darkblue;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-shadow: 0 2px 8px rgba(0,0,0,0.1);
    z-index: 1000;
    animation: slideDown 0.3s ease-in-out;
  }
  
  .popup--share.animate--slideup {
    animation: slideUp 0.3s ease-in-out forwards;
  }
  
  .popup--share ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .popup--share li {
    padding: 10px 15px;
    cursor: pointer;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &:hover {
      background-color: $erste-blue;
      color:#fff;
    }
  }
  
  @keyframes slideDown {
    from {
      opacity: 0;
      transform: translateX(-50%) translateY(-20px);
    }
    to {
      opacity: 1;
      transform: translateX(-50%) translateY(0);
    }
  }
  
  @keyframes slideUp {
    from {
      opacity: 1;
      transform: translateX(-50%) translateY(0);
    }
    to {
      opacity: 0;
      transform: translateX(-50%) translateY(-20px);
    }
  }

  .popupmini--info {
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    background-color: black;
    color: white;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.3);
    z-index: 2000;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
    visibility: hidden;

    &.is-visible {
      opacity: 1;
      visibility: visible;
    }
  }
  .skala--legend {
    padding-bottom: 3.5rem;
    gap: 0.5rem;
    .skala__a, .skala__b, .skala__c, .skala__d, .skala__e, .skala__f, .skala__g {
      padding: 0.5rem 0.75rem;
      gap: 0.5rem;
      height: fit-content;
    }
  }

  .skala {
    .skala__a, .skala__b, .skala__c, .skala__d, .skala__e, .skala__f, .skala__g {
      box-shadow: 0px 2px 8px 0px #0B1F421A;
      font-weight: 600;
    }
    justify-content: center;
    .skala__a {
      background-color: #006634;
    }
    .skala__b {
      background-color: #018D37;
    }
    .skala__c {
      background-color: #93C120;
    }
    .skala__d {
      background-color: #FFEB04;
    }
    .skala__e {
      background-color: #F7B231;
    }
    .skala__f {
      background-color: #E95A0C;
    }
    .skala__g {
      background-color: #BC1622;
    }
  }
}

.gm-style-mtc-bbw {
  display: none;
}

.maps-container-wrapper {
  height: 400px;
}

@media all and (min-width: $mobile_break) {
  .maps-container-wrapper {
    height: 500px;
  }
  #immodetail {
    #documents-headline {
      padding-top: 0rem;
      text-align: left;
  }
  .documents-subheadline {
    font-size: unset;
    margin-top: 1rem;
    text-align: left;
  }
    .skala--legend {
      gap: 0.75rem;
      .skala__a, .skala__b, .skala__c, .skala__d, .skala__e, .skala__f, .skala__g {
        padding: 1rem;
        height: unset;
      }
    }
    .immodetail__favicon {
      position: relative;
      margin-left: 9px;
      margin-bottom: 5px;
    }

    .immodetail__list {
        width: 30%;
    }

    .immodetail__features-wrapper {
      .immodetail__features--val {
        font-size: 1.4rem;
      }
    }

    .popup--share-wrapper {
      
    }

    .popup--share {
      left: 100%;
    }

    h1 {
      font-size: 2rem;
    }

    h2 {
      font-size: 1.7rem;
      margin-top: 5rem;
      margin-bottom: 2rem;
      text-align: left;
    }

    h3 {
      text-align: left;
    }

    .immodetail__headline h1 {
      float: left;
    }

    .w-desktop--center.immodetail__location-wrapper {
      padding-bottom: 1rem;
    }

    .immodetail__location--withgooglemaps > div:last-child {
      min-height: 500px;
      > div:last-child {
        min-height: 500px;
      }
    }

    .immodetail__desc {
      padding: 4rem 0;
      margin-top: 3rem;
      background-color: $lightgray-backgrounds;

      h2 {
        margin-top: 0;
        text-align: center;
      }
      p {
        margin-bottom: 5px;
      }
      ul {
        margin-left: 1rem;
        margin-bottom: 0.5rem;
      }
      li {
        margin-bottom: 0.5rem;
      }

      .immodetail__location.immodetail__location--withgooglemaps {
        flex-direction: row;

        .immodetail__location--address,
        .maps-container-wrapper {
          flex: 0 0 50%;
          width: 50%;
          min-height: 320px;
        }

        .immodetail__location--address {
          align-items: center;
          justify-content: center;
          background-color: $erste-blue;
          color: #fff;
          padding: 2rem;
          box-sizing: border-box;
          font-size: 1.5rem;
          line-height: 1.4;

          span {
            background-color: $erste-blue !important;
          }
        }
      }
    }

    .card--finanzierung {
      h2 {
        margin-top: 0;
      }
    }

    .immodetail-desktop {
      display: flex;
      gap: 4%;
      .immodetail__table {
        flex: 0 0 48%;
      }
    }
    
    .immodetail-mobile {
      display: none !important;
    }

    .immodetail__price-agent--wrapper {
      flex-direction: row;
      padding-top: 5rem;
      gap: 4%;

      .immodetail__price--wrapper {
        h2 {
          margin-top: 0;
        }
      }
    }

    .immodetail--fixed-wrapper {
      border-top: 1px solid $lightgray;

      .w-desktop--center {
        padding-top: 0;
        padding-bottom: 0;
      }

      .immodetail--fixed {
        .immodetail--fixed__text {
          flex-direction: column;
          width: unset;
          align-items: start;
        }
        .immodetail__price {
          font-size: 2rem;
        }
      }
      .immodetail--fixed--webangebote {
        flex-direction: row;
        span, h4 {
          font-size: unset;
        }
        .card {
          width: unset;
          flex-direction: row;
          align-items: center;
          gap: 1rem;
          svg {
            display: unset;
          }
          h4 {
            max-width: 14rem;
          }
        }
      } 
    }
  }
}

@media all and (max-width: $mobile_break) {

  .immodetail__price--wrapper {
    div {
      margin-right: 0 !important;
    }
  }
  .immodetail-desktop {
    display: none !important;
  }
}
