@import '../../../../shared/styles/sreal_default/vars/colors.scss';
@import '../../../../shared/styles/sreal_default/vars/breaks.scss';

.sources-data-container {
    display: flex;
    flex-wrap: wrap;

    div {
        width: 25%;
        margin-bottom: 0.5rem;
    }
}

.recharts-cartesian-axis-line {
    stroke: white;
}

@media all and (max-width: $mobile_break) {
    .sources-data-container {
        div {
            width: 100%;
        }
    }
}