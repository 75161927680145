@import '../../../../shared/styles/sreal_default/vars/colors.scss';
@import '../../../../shared/styles/sreal_default/vars/breaks.scss';

.marktinfolight-card {
  box-sizing: border-box;
  background-color: $verylightgray;

  &.bg-white {
    .marktinfolight__result--prev {
      background-color: white;
    }
    .kennzahlen {
      background-color: white;
    }
  }

  label {
    color: $gray;
    font-weight: 600;
    font-family: Inter;
    font-size: 1rem;
  }
}

.marktinfolight__result {
  .form__field--reset {
    display: none;
  }
  .marktinfolight__result__content {
    width: 100%;
    border-radius: 5px;

    h3 {
      padding: 1rem;
      margin-top: 0;
    }

    .kennzahl__pois h3 {
      padding: 0;
      margin-top: 2.5rem;
      font-size: 1.2rem;
    }

    .kennzahlen-wrapper {
      border-top: 1px solid #fff;
      padding-top: 2rem;
      margin-top: 1rem;

      .kennzahlen__text {
        padding: 2rem;
        box-sizing: border-box;
      }
    }
  }

  .marktinfolight__result--prev {
    label {
      color: $erste-darkblue;
    }
  }
}

@media all and (min-width: $mobile_break) {
  .marktinfolight__content {
    .lh-150 {
      display: none;
    }
  }
  .marktinfolight__result--prev {
    // background-color: $verylightgray;
    padding-left: 0 !important;
    width: 100%;
  }

  .marktinfolight-card {
    .marktinfolight__result--prev {
      width: 50%;
    }
  }
  .marktinfolight-card__marktanalys__headline {
    margin-top: 1rem;
  }
}

.marktinfolight-card__marktanalyse {
  .kennzahlen-wrapper {
    background-color: $lightstone;
    color: #fff;
    padding: 1rem;
    margin-top: 1rem;
    border-radius: 5px;
  }
}

//Marktinfopro card alone
.card.marktinfopro-card {
  .card__icon {
    display: none;
  }
  .card__content {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    div {
      flex-basis: 50%;
    }

    .icon-block {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
    }

    .card__icon {
      display: block;
      padding: 0;
      svg {
        transform-origin: center;
      }
    }
    span {
      display: inline-block;
      text-align: center;
    }
  }

  .button-panel {
    margin-bottom: 0;
    button:last-child {
      margin-bottom: 0;
    }
  }
}

//Marktinfopro card as col element
.marktinfopro-card.elements2cols__col,
.marktinfopro-card {
  background-color: $erste-blue;
  color: #fff;

  .card__icon {
    display: inline-block;
    padding-top: 2.5rem;
    padding-left: 1.5rem;
    height: 9rem;
    svg {
      transform: scale(2.2);
      transform-origin: top left;
    }
  }
  .icon-block {
    .card__icon {
      display: none;
    }
  }
  .card__content {
    min-height: 350px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;

    span {
      display: block;
      width: 100%;
      text-align: start;
    }

    button {
      a {
        text-decoration: none;
      }
    }
  }
}

.marktinfolight-card__marktanalys__txt-desktop {
  display: none;
}

//Dialog
.pagedialog {
  .marktinfolight__result {
    height: 100%;
    background-color: $lightstone;
    .marktinfolight__result__content {
      background-color: $lightstone;
      border-radius: 0;

      h3 {
        width: 100%;
        margin: 0;
        color: white;
        display: inline-block;
        background-color: $lightstone;
      }

      .kennzahlen-wrapper__circle-bar {
        flex-direction: column;

        .kennzahl__circlebar-wrapper,
        .kennzahlen__text {
          width: 100%;
        }

        .kennzahlen__text {
          margin-top: 0;
          padding: 2rem !important;
        }

        .kennzahl__circlebar {
          .kennzahl__circlebar__icon {
            svg {
              path {
                // fill: $erste-blue;
              }
            }
          }
        }
      }

      .kennzahl__list {
        flex-direction: column;
        margin-top: 2rem;
      }
    }

    .marktinfolight__result__savedInfo {
      border-radius: 0;
      margin-bottom: 0;
    }
  }

  .marktinfolight__result__savedInfo {
    padding: 2rem;
    background-color: $lightstone;
    margin-top: -2rem;
    margin-bottom: 2rem;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    div {
      background-color: $lightgray;
      border-radius: 5px;
      padding-top: 1rem;
    }
  }
}

.marktinfolight-card__marktanalyse .marktinfolight__result__weitere {
  display: none;
}

@media all and (min-width: $mobile_break) {
  .button-panel-marktinfo {
    display: flex;
    align-items: center;
    gap: 50px;
  }

  .marktinfolight-card__marktanalys__headline {
    display: flex;
    justify-content: space-between;
  }
  .marktinfolight__result__content {
    h3 {
      display: none;
    }
  }
  .kennzahl__pois {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 2rem;
    div {
      width: 48%;
    }
  }
  .marktinfolight-card__marktanalyse {
    .kennzahlen-wrapper {
      min-height: 400px !important;
      .kennzahl__piechart {
        margin-top: 2.5rem;
      }
    }
    .marktinfolight-card__marktanalys__txt-mobil {
      display: none;
    }
    .marktinfolight-card__marktanalys__txt-desktop {
      display: unset;
      color: $erste-darkblue;
      margin-bottom: 0;
    }
  }
  .kennzahlen__text {
    padding: 0rem;
  }
}

.marktinfopro__content {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: flex-end;
  flex: 0 0 100%;
  width: 100%;

  .marktinfopro__content__item {
    display: flex;
    flex: 0 0 30%;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    float: left;

    .textlink {
      font-size: 0.8rem;
      color: $erste-darkblue;
      padding-top: 0.5rem;
    }
  }
}

.marktinfolight__result__content {
  .filtermenu-wrapper-right {
    margin-left: 1rem;
    width: calc(100% - 1rem);

    .filtermenu {
      padding-right: 1rem;
      border-bottom-right-radius: 0;
      border-top-right-radius: 0;
    }
  }
}

@media all and (max-width: $mobile_break) {
  .marktinfolight-card__marktanalyse {
    .kennzahlen-wrapper {
      height: fit-content !important;
    }
  }
  .marktinfolight-card__marktanalyse {
    padding: 1rem;
  }
  .marktinfolight__result--prev {
    padding: 0 !important;
    p {
      color: $erste-darkblue;
    }
  }

  //Marktinfopro card alone
  .card.marktinfopro-card {
    .card__content {
      flex-direction: column;
      .icon-block {
        margin-top: 2rem;
        align-items: flex-start;
      }
      .card__icon {
        padding-left: 1.7rem;
      }
      span {
        text-align: start;
      }
    }
  }
}

.pagedialog {
  .marktinfolight__result--prev {
    background-color: $lightstone !important;
    padding: 1rem !important;
    label,
    p {
      color: white;
    }
  }
}
