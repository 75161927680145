@import '../../../../shared/styles/sreal_default/sreal_default_styles.scss';
@import '../../../../shared/styles/sreal_default/vars/colors.scss';
@import './print.scss';

/* BASICS */

html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  font-family: 'Inter';
  margin: 0;
  color: $erste-darkblue;
}

body,
button,
html,
input,
p,
select,
textarea {
  font-family: Inter, sans-serif;
}

abbr,
address,
article,
aside,
audio,
b,
blockquote,
body,
body div,
caption,
cite,
code,
dd,
del,
details,
dfn,
dl,
dt,
em,
fieldset,
figure,
footer,
form,
header,
html,
i,
iframe,
img,
ins,
kbd,
label,
legend,
li,
mark,
menu,
nav,
object,
ol,
p,
pre,
q,
samp,
section,
small,
span,
strong,
sub,
summary,
sup,
table,
tbody,
td,
tfoot,
th,
thead,
time,
tr,
ul,
var,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-weight: 400;
  vertical-align: baseline;
  background: transparent;
}

// Contact Form
a[href^='tel:'] {
  color: $erste-lightblue2;
  text-decoration: none;
}
a[href^='mailto:'] {
  color: $erste-lightblue2;
  text-decoration: none;
}

.gray-background {
  background-color: $lightgray-backgrounds;
  padding-bottom: 0;
  margin-top: 2.5rem;
}

.light-aubergine-background {
  background-color: $light-aubergine;
  padding-bottom: 0;
  margin-top: 2.5rem;
}

.light-blue-background {
  background-color: $light-blue;
  padding-bottom: 0;
  margin-top: 2.5rem;
}

.light-teal-background {
  background-color: $light-teal;
}

.gray-background--no-margin {
  margin-top: 0 !important;
}

.cursor-pointer {
  cursor: pointer;
}

.z-index-1 {
  z-index: 1;
}

.no-wrap {
  white-space: nowrap;
}

.loading-info {
	cursor: default;
	color: $erste-blue;
	display: flex;
	align-items: center;
	gap: 10px;
	margin-top: 30px;
	margin-bottom: -10px;
	svg {
		 height: 1.5rem;
		 width: fit-content;
	}
	
	&.loading-info--inactive {
		opacity: 0;
	}
}

// Sticky footer
#sreal-kundenportal-root {
  display: flex;
  min-height: 100vh;
  flex-direction: column;

  &.no-scroll {
    width: 100%;
  }

  main.main-content {
    flex: 1;
    z-index: 2;
  }

  .w-desktop--center, .w-desktop--center-residential {
    width: 100%;
    padding: 1rem;
    box-sizing: border-box;
    margin-left: auto;
    margin-right: auto;

    &.z-index-1 {
      z-index: 1;
    }

    &.z-index-999 {
      z-index: 999;
    }
  
    &.z-index-9999 {
      z-index: 9999;
    }
    
    &.position-relative {  
      position: relative;
    }
  }
}

.headline--witharrow {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;

  h2 {
    margin: 0;
    width: calc(100% - 20px);
  }

  .headline__arrow {
    cursor: pointer;
    svg {
      width: 20px;
      height: auto;
    }
  }
}

.headline--withfiltermenu {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;

  .filtermenu-wrapper-right,
  .filtermenu-wrapper-none {
    width: 100%;
    margin: 0;
    margin-top: 1rem;
  }
}

.elements3cols,
.elements2cols {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: stretch;

  .elements3cols__col,
  .elements2cols__col {
    width: 100%;
    flex: 0 0 100%;
  }

  .elements2cols {
    &.flex-wrap {
      flex-wrap: wrap;
    }
  }
}
#sreal-kundenportal-root {
  .elements3cols.elements3cols--aktuelles {
  	justify-content: left;
  	gap: 2%;
	}
}

/* FIXED BAR */
.fixedbar-wrapper {
	position: fixed;
  border-top: 1px solid white;
	left: 0;
	bottom: 0;
	width: 100vw;
	max-width: 100vw;
	background-color: $erste-blue;
  z-index: 101;

	.w-desktop--center {
		padding-left: 0;
		padding-right: 0;
	}

	.fixedbar__content {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: flex-start;
		padding: 2rem;

		button, .button {
			white-space: nowrap;
			margin-top: 0;
			margin-top: 1rem;
		}

		h2, p {
			color: #fff;
		}

		h2 {
			font-size: 1.1rem;
			margin: 0;
		}
	}
}


@media (min-width: $mobile_break) {
  #sreal-kundenportal-root {
    main.main-content {
      &--immosuche {
        margin-top: -2rem;
      }
    }

    .w-desktop--center {
      max-width: $max_tablet_width;
      padding: 0;
      padding-top: 2rem;
    }
    .elements3cols,
    .elements2cols {
      flex-direction: row;
      justify-content: space-between;
      align-items: stretch;

      .elements3cols__col {
        width: 32%;
        flex: 0 0 32%;
      }

      .elements2cols__col {
        width: 48%;
        flex: 0 0 48%;
      }
    }

		/* FIXED BAR */
		.fixedbar-wrapper {
			.fixedbar__content {
				flex-direction: row;
				align-items: center;

				button, .button {
					margin-top: 0;
				}
			}
		}
  }

  .gray-background, .light-aubergine-background, .light-blue-background, .light-teal-background {
    padding-bottom: 3rem;
    padding-top: 3rem;
  }

  .headline--withfiltermenu {
    flex-direction: row;

    .filtermenu-wrapper-right,
    .filtermenu-wrapper-none {
      width: auto;
      margin-top: 0;
    }
  }
}

@media (min-width: $desktop_break) {
  #sreal-kundenportal-root {
    .w-desktop--center, .w-desktop--center-residential {
      max-width: $max_desktop_width;
    }
  }
}

/* Hide scrollbar for Chrome, Safari and Opera */
.filtermenu::-webkit-scrollbar,
.linkitem-collection::-webkit-scrollbar,
.desktop--box_item::-webkit-scrollbar {
  display: none !important;
}

/* Hide scrollbar for IE, Edge and Firefox */
.filtermenu,
.desktop--box_item,
.linkitem-collection {
  -ms-overflow-style: none !important; /* IE and Edge */
  scrollbar-width: none !important; /* Firefox */
}

@media all and (max-width: $mobile_break) {
  .w-desktop--slider {
    padding: 2rem;
    .slick-track {
      //transform: translate3d(0px, 0px, 0px) !important;
    }
  }
  .w-mobile--slider {
    .carousel {
      margin-right: -2rem;
    }
    .slick-track {
      padding-right: 2rem;
    }
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.nobreak {
  white-space: nowrap;
}

@media (min-width: $mobile_break) {
	.nachrichten {

		.page__loadingmessage, .page__emptymessage {
			color: #fff;
		}
	}
  #sreal-kundenportal-root {
    .w-desktop--center {
      padding: 2rem;
  }
}
}