@import '../../../../shared/styles/sreal_default/vars/colors.scss';
@import '../../../../shared/styles/sreal_default/vars/breaks.scss';

$icon_width: 3rem;
$arrow_width: 1.5rem;

.linkitem {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  // align-items: center;
  align-items: stretch;
  border-radius: 0.8rem;
  background-color: #fff;
  padding: 1rem;
  margin: 1rem 0;
  cursor: pointer;
  box-shadow: 0px 2px 8px 0px #0B1F421A, 0px 1px 1px 0px #0B1F420A;

  &.is-hidden {
    display: none;
  }

  .linkitem__icon {
    display: flex;
    flex-direction: column;
    align-self: center;

    background-color: $erste-blue;
    width: $icon_width;
    min-width: $icon_width;
    height: $icon_width;
    border-radius: 50%;
    margin-right: 1rem;

    overflow: hidden;
    position: relative;

    img,
    svg {
      // height: 1.8rem;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      // max-width: 2.5rem;
      max-height: 2rem;
      max-width: 2.5rem;
    }

    img {
      min-width: $icon_width;
      min-height: $icon_width;
      object-fit: cover;
      object-position: center;
    }
  }

  .linkitem__content {
    display: flex;
    flex-direction: column;
    width: calc(100% - $icon_width - $arrow_width - 1rem);
    align-self: center;
    overflow: hidden;

    &.linkitem__content--smaller {
      width: calc(100% - $icon_width - 130px);
    }
  }

  .linkitem__headline {
    font-weight: 700;
    font-size: 1rem;
    color: $erste-darkblue;
    margin: 0;
    margin-bottom: 0.25rem;
    hyphens: auto;
  }

  .linkitem__text {
    font-size: 0.9rem;
    color: $erste-darkblue;
    hyphens: auto;
    overflow: hidden;
  }

  .linkitem__right {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-left: 1rem;
    position: relative;
    flex: 1;

    .linkitem__info {
      font-weight: 400;
      font-size: 0.8rem;
      justify-self: flex-start;
    }

    .linkitem__arrow-wrapper {
      .linkitem__arrow {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);

        svg {
          max-height: $arrow_width;
        }
      }
    }

    .linkitem__dotunread {
      position: absolute;
      bottom: 0;
    }
  }

  &.linkitem--blue {
    background-color: $erste-blue;

    .linkitem__icon {
      background-color: #fff;
    }

    .linkitem__headline,
    .linkitem__text,
    .linkitem__info {
      color: white;
    }
  }

  &.linkitem--einzeilig-desktop:not(.linkitem--blue) {
    .linkitem__headline, .linkitem__text {
      color: $erste-blue;
    }
  }

  &.linkitem-purple {
    .linkitem__icon { 
      background-color: $purple;
    }
  }

  &.linkitem-green {
    .linkitem__icon {
      background-color: $green;
    }
  }

  &.linkitem-turquoise {
    .linkitem__icon {
      background-color: $turquoise;
    }
  }

  &.linkitem-newblue {
    .linkitem__icon {
      background-color: $newblue;
    }
  }

  &.dokitem--open {
    .linkitem__icon {
      background-color: $orange;
    }
  }

  &.dokitem--category {
    .linkitem__icon {
      background-color: $green;
    }
  }

  &.linkitem--IconHouseSearch {
    .linkitem__icon {
      svg {
        height: auto;
        transform: unset;
        left: 0.55rem;
        top: 0.6rem;
        width: 2.2rem;
      }
    }
  }

  &.linkitem--placeholder {
    border: none;
    background-color: transparent;
	box-shadow: none;
    cursor: initial;
  }
}

@media all and (min-width: $mobile_break) {
  .linkitem {
    &.linkitem--einzeilig-desktop {
      padding-top: 0;
      padding-bottom: 0;
      .linkitem__content {
        display: flex;
        gap: 20px;
        flex-direction: row;
      }
      .linkitem__headline {
        margin-bottom: 0;
      }
      .linkitem__text {
        padding-top: 2px;
      }
    }
    &.linkitem--blue-profile {
      .linkitem__right {
        min-height: unset !important;
      }
    }
  }
}

.linkitem-collection {
  margin-top: 1rem;
  margin-bottom: 1rem;

  .linkitem {
    margin-top: 0;
    margin-bottom: 0;
    border: none;
    border-radius: 0;

    &:not(:last-child) {
      border-bottom: 1px solid $lightgray;
    }
  }
  .linkitem:last-child {
    border-bottom-left-radius: 0.8rem;
    border-bottom-right-radius: 0.8rem;
  }
  .linkitem:first-child {
    border-top-left-radius: 0.8rem;
    border-top-right-radius: 0.8rem;
  }
}

.accordion {
  .linkitem-collection {
    border-left: 0;
    border-right: 0;
    border: 0;
    margin: 0;
  }
}

.show-less {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  color: $erste-blue;
  text-align: right;
  cursor: pointer;
}

.is-closed {
  position: relative;
  .ebene0 {
    margin-top: 0;
  }
  .ebene1 {
    position: relative;
    margin-top: -95px;
    z-index: -1;
    transform: scale(0.95);
    transition: 1s;
    -webkit-transition: 1s;
  }

  .ebene2 {
    position: relative;
    margin-top: -85px;
    transform: scale(0.9);
    transition: 1s;
    z-index: -2;
    .linkitem__flex-content-right {
      display: none;
    }
  }
}

.is-open {
  .ebene1 {
    position: relative;
    margin-top: 0;
    // z-index: -1;
    transform: scale(1);
    transition: 1s;
    -webkit-transition: 1s;
  }

  .ebene2 {
    position: relative;
    margin-top: 0;
    transform: scale(1);
    transition: 1s;
    // z-index: -2;
    .linkitem__flex-content-right {
      display: none;
    }
  }
}

.elements3cols__col {
  .linkitem__text,
  .linkitem__headline {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}

$activitiesCount: 3;
@while $activitiesCount < 210 {
  $childItemIndex: $activitiesCount + 1;
  .elements3cols--show#{$activitiesCount} > .linkitem:nth-child(n+#{$childItemIndex}),
  .elements3cols--show#{$activitiesCount} > .aktuellescontainer-wrapper:nth-child(n+#{$childItemIndex}) {
    display: none;
  }

  .elements3cols--show#{$activitiesCount} > .linkitem--blue:nth-child(n+#{$childItemIndex}) {
    display: flex;
  }

  $activitiesCount: $activitiesCount + 3;
}

.aktuellescontainer-wrapper {
  width: 100%;
  flex: 0 0 100%;
  .aktuellescontainer {
    height: 9rem;
    position: absolute;
    max-width: 100%;
    left: 0;
    right: 0;
    z-index: 10;
    margin-left: 1.5rem;
    margin-right: 1.5rem;
    cursor: pointer;
  }

  &.is-open {
    margin-bottom: 2rem;

    .aktuellescontainer {
      height: 0;
      z-index: -10;
    }
  }

  .aktuellescontainer__topline {
    display: flex;
    width: 100%;
    flex: 0 0 100%;
    justify-content: space-between;

    .aktuellescontainer__topline__date {
      color: $erste-blue;
    }
  }
}

.aktuelles-container {
  .linkitem {
    height: 115px;
  }
  @media all and (min-width: $mobile_break) {
    display: flex;
    gap: 5%;
    flex-wrap: wrap;
    .linkitem {
      width: 30%;
    }
  }
  @media all and (min-width: $mobile_break) and (max-width: $desktop_break) {
    gap: 10%;
    flex-wrap: wrap;
    .linkitem {
      width: 45%;
    }
  }
}

@media all and (max-width: $mobile_break) {
  .linkitem--placeholder {
    display: none;
  }

  .aktuellescontainer-children.is-closed {
    .linkitem {
      height: 80px;
    }

    .linkitem__text {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      line-height: 18px;
      height: 18px;
    }
  }

  .linkitem.elements3cols__col {
    margin-bottom: 0;
  }

  .is-open {
    .linkitem.elements3cols__col {
      margin-bottom: 1rem;
    }
  }

  .elements3cols {
    .aktuellescontainer-wrapper {
      margin-top: 1rem;

      .is-closed {
        .ebene1 {
          margin-top: -68px;
        }

        .ebene2 {
          margin-bottom: 0;
        }
      }
    }
  }
}
