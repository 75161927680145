@import '../../../../shared/styles/sreal_default/vars/colors.scss';
@import '../../../../shared/styles/sreal_default/vars/breaks.scss';

// INFO-DIALOG
.infodialog {
  margin-top: 70px;

  p {
    line-height: 1.5;
    font-size: 1rem;
  }

  .MuiDialogTitle-root {
    position: relative;
    text-align: center;
    padding: 1rem 50px;
    font-weight: 600;
    margin-bottom: 1rem;

    svg {
      position: absolute;
      top: 55%;
      right: 24px;
      transform: translateY(-50%);
      scale: 2;
      padding: 0.1rem;
    }
    .dialog-headline-icon {
      position: relative;
    }
  }
}

@media (min-width: $mobile_break) {
  .infodialog {
    margin-top: 0px;
  }
}

// PAGE-DIALOG
.pagedialog {
  .MuiDialogTitle-root {
    position: relative;
    text-align: center;
    color: $erste-darkblue;
    font-weight: 600;

    &.pagedialog__title--blue {
      background-color: $erste-blue;
      color: #fff !important;
    }

    svg {
      position: absolute;
      top: 65%;
      transform: translateY(-50%);
      scale: 2;
      padding: 0.35rem;

      &.pagedialog__close {
        right: 18px;
        cursor: pointer;
      }

      &.pagedialog__back {
        left: 24px;
        cursor: pointer;
      }
    }
    .dialog-headline-icon {
      position: relative;
    }
  }

  .MuiDialogContent-root {
    padding: 0;
    box-sizing: border-box;
  }

  .page__emptymessage {
    color: $erste-darkblue;
  }

  .cardgrid {
    .card {
      width: 100% !important;
      height: auto !important;
    }
  }
}

@media (min-width: $mobile_break) {
  .pagedialog {
    .MuiDialogTitle-root {
      background-color: white;
      color: $erste-darkblue;
    }
  }
}

//media Slider Dialog
.pagedialog--mediaslider.pagedialog {
  .MuiDialog-container .MuiPaper-root {
    width: 100%;
  }
}

//cookie Dialog
.pagedialog--cookie.pagedialog {
  .MuiDialog-container .MuiPaper-root {
    width: 100%;
  }
  .MuiTypography-root {
    background-color: $erste-blue;
  }
  .MuiDialogContent-root {
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #fff;
    @media (min-width: $desktop_break) {
      padding-left: 10rem;
      padding-right: 10rem;
    }
    padding: 2rem;
    h2 {
      color:#fff;
    }
    .textlink {
      color: #fff;
      font-weight: 600;
    }
  }
  .MuiPaper-root {
    background-color: $erste-blue;
  }
  .MuiButtonBase-root {
    text-transform: none;
    @media (max-width: $mobile_break) {
      margin: 1rem;
      border: 1px solid !important;
    }
  }
  .MuiToggleButtonGroup-root .MuiToggleButtonGroup-grouped:not(:last-of-type) {
    @media (max-width: $mobile_break) {
      margin-right: 0.5rem !important;
      border-radius: 5px;
      margin: 0;
    }
  }
  .MuiToggleButtonGroup-root .MuiToggleButtonGroup-grouped:not(:first-of-type) {
    @media (max-width: $mobile_break) {
      border-radius: 5px;
      margin: 0;
    }
  }
  .MuiToggleButtonGroup-root .MuiToggleButtonGroup-grouped {
    padding: 10px 20px 10px 20px;
    margin-top: 1rem !important;
    margin: 0;
  }
  .Mui-selected {
    background-color: $erste-blue !important;
    color: #fff !important;
  }
}
.pagedialog-cookie2.pagedialog {
  .MuiDialogContent-root {
    justify-content: center;
    @media (max-width: $mobile_break) {
      justify-content: flex-start;
    }
  }
}

.cookie-banner__small {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 9999;
  background-color: $erste-blue;
  padding: 20px;
  padding-left: 20px;
  display: grid;
  grid-template-columns: 5% 75% 20%;
  color: #fff;

  .cookie-banner__small__cookie-icon {
    font-size: 30pt;
  }

  .cookie-banner__small__buttons {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-end;
  }

  @media (max-width: $mobile_break) {
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    .cookie-banner__small__cookie-icon {
      font-size: 30pt;
      margin: auto;
      margin-bottom: 20px;
    }

    .cookie-banner__small__buttons {
      margin-top: 20px;
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      align-items: center;
    }
  }
}

.pagedialog__icon {
  left: 24px;
  height: 24px;
  width: fit-content;
}

.css-bdhsul-MuiTypography-root-MuiDialogTitle-root {
  font-family: Inter, sans-serif !important;
}

//Flyout

.flyout {
  background-color: white;
  border: 1px solid $dropdownborder;
  position: absolute;
  border-radius: 0.4rem;
  top: 9.25rem;
  width: 350px;
  right: 4.75rem;
  height: fit-content;
  z-index: 101;
  padding: 2rem;
  span,
  p {
    color: $erste-darkblue !important;
  }
  .button--withicon {
    span {
      color: white !important;
    }
  }
  .image--round {
    background-color: $erste-blue;
    position: relative;
  }
  .header__userpic {
    position: absolute;
    right: 1.2rem;
    top: 0.85rem;
    cursor: pointer;
    .image--round {
      background-color: #fff;
    }
  }
}
@media (max-width: $mobile_break) {
  .flyout {
    display: none;
  }
}

//Backdrop
.css-zw3mfo-MuiModal-root-MuiDialog-root {
  .css-yiavyu-MuiBackdrop-root-MuiDialog-backdrop {
    background-color: rgba(0, 0, 0, 0.62);
  }
}

@media all and (min-width: $desktop_break) {
    .flyout {
      top: 8rem;
    }
}

@media all and (min-width: calc($max_header_width + 6.4rem)) {
  .flyout {
    --widthcalc: calc(100% - 1380px);
    right: calc(var(--widthcalc) / 2 + 0.75rem);
  }
}

//Contactform
.contactForm {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  .button-panel {
    margin-top: auto;
  }
}

#searchProfileList {
  display: flex;
  flex-direction: column;
  padding: 1rem;
}
