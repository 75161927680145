@import '../../../../shared/styles/sreal_default/vars/colors.scss';

.fileUploadContainer{
    width: 100%;
    height: 200px;
    background-color: white;
    border-radius: 4px;
    padding: 10px;
    font-size: 0.9rem;
    border: 1px solid $erste-blue;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.fileUploadContainer:hover{
    p {
        text-decoration: underline;
    };
}

.fileUploadContainer.dragOver{
    border: 2px dashed $erste-blue;

    p {
        text-decoration: underline;
    };
}


.fileUploadInner {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    p {
        font-size: 0.9rem;
        margin-top: 15px;
        font-weight: 600;
        color: $erste-blue;
    }
}

.fileUploadIconText {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: $erste-blue;

    span {
         margin-top: 10px;
         font-size: 0.9rem;
    }
}

.deleteFileContainer {
    width: 100%;
    height: 10px;
    margin-top: 0.5rem;

    span {
        color: $erste-blue;
        font-size: 0.9rem;
        font-weight: 600;
    }
}

.fileUploadErrorContainer {
    width: 100%;
}