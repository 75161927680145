@import '../vars/colors.scss';

.form__title {
   font-size: 1.2rem;
   margin-top: 20px;
   margin-bottom: 20px;

   &.mt-0 {
      margin-top: 0;
   }
}

.form--info {
   display: inline-block;
   font-size: 0.9rem;
   margin-bottom: 2rem;
}

.form__field {
   margin-bottom: 20px;
}

.form__fielderror {
   display: flex;
   flex: 1 0 100%;
   width: 100%;
   color: $erste-red;
   padding: 0;
   font-size: 0.9rem;
   margin: 0;
   margin-top: 0.5rem;
   margin-bottom: 1rem;
}

.form__row {
   display: flex;
   flex-direction: row;
   flex-wrap: wrap;
   justify-content: space-between;
   width: 100%;
   flex: 0 0 100%;
   margin-bottom: 20px;

   &.form__row--double {
      .form__field {
         margin-bottom: 0;
         flex: 0 0 48%;
         width: 48%;
         .form__field {
            width: 100%;
         }
      }
   }

   &.form__row--double--phone {
      .form__field:first-child {
         flex: 0 0 8rem;
         width: 8rem;
         & label {
            color: inherit;
            font-size: 0.9rem;
            line-height: 17.5px;
            margin-bottom: 1.5px;
         }
      }
      .form__field:nth-child(2){
         flex-grow: 1;
         margin-left: 4%;
      } 
   }
   
   &.form__row--double--mobilefull {
      margin-bottom: 0;
      .form__field {
         flex: 0 0 100%;
         width: 100%;
         margin-bottom: 20px;
      }
   }
}

label {
   display: inline-block;
   font-size: 0.9rem;
   margin-bottom: 5px;
}

.input,
.select,
.mui-input .MuiOutlinedInput-root,
.input-list li,
.form__field--checkbox--bordered {
   width: 100%;
   background-color: white;
   border: 1px solid $midgray !important;
   box-shadow: none;
   border-radius: 4px;
   color: $darkgray;
   position: relative;

   font-weight: 500;
   height: 45px;
   margin-top: 3px;
   padding: 10px;
   font-size: 1rem;
   transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

   &:focus {
      box-shadow: none;
      outline: none;
   }
}

.select__phoneprefix {
   display: grid;
   width: 100%;
   grid-template-columns: 30% 70%;
}

.input:disabled,
.select:disabled {
   color: rgb(165, 165, 165);
}

.MuiInputBase-root.select,
.MuiInputBase-root.select:hover {
   padding: 0;
}

.MuiPopover-root,
.MuiAutocomplete-popper {
   .MuiPaper-root {
      max-height: 30rem;
      margin-top: -5px;
      background-color: white;

      .Mui-selected {
         background-color: $erste-blue;
         color: #fff;
      }

      .MuiListSubheader-root {
         background-color: white;
         font-weight: bold;
      }
   }
}

.input-wrapper:has(.input--currency)::before {
   content: 'EUR';
   position: absolute;
   z-index: 9999;
   pointer-events: none;
   color: black;
   right: 10px;
   top: 48%;
   transform: translateY(-48%);
}

.input-wrapper:has(.input--years)::before {
   content: 'Jahre';
   position: absolute;
   z-index: 9999;
   pointer-events: none;
   color: black;
   right: 10px;
   top: 48%;
   transform: translateY(-48%);
}

.input-wrapper,
.select-wrapper {
   position: relative;
   input {
      padding-right: 30px;
   }

   .form__field--reset {
      position: absolute;
      top: 50%;
      right: 10px;
      transform: translateY(-50%);
      margin-top: 2px;
      color: #000;
      width: 12px;
      height: auto;
      cursor: pointer;
   }

   &.locked {
      input, .MuiSelect-select {
         background-image: url(../../../icons/lock_grey.svg);
         background-repeat: no-repeat;
         background-size: auto 22px;
         background-position: calc(100% - 10px) 50%;
      }

      &.formfield--withreseticon {
         input, .MuiSelect-select {
            background-position: calc(100% - 30px) 50%;
         }
      }
      svg {
         display: none;
      }
   }

   .MuiInputBase-root .MuiSelect-select {
      padding-right: 45px !important;
   }

   &.input--loading {
      input {
         background-image: url(../../../icons/loadingspinner_grey.svg);
         background-repeat: no-repeat;
         background-position: calc(100% - 28px) 50%;
         background-size: auto 21px;
      }
   }
}

.select-wrapper {
   .form__field--reset {
      right: 30px;

      &.form__field--reset-autocomplete {
         right: 10px;
      }
   }
}

.autocomplete__deleteicon {
   display: flex;
   align-items: center;
   justify-content: center;
}

.select-multiple {
   .MuiInputBase-root.MuiInput-root,
   .MuiSelect-select.MuiSelect-multiple {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: flex-start;
      text-overflow: auto;
      white-space: initial;
      overflow: initial;

      .MuiSelect-select.MuiSelect-multiple {
         padding-right: 55px !important;
      }

      .MuiChip-root {
         position: relative;
         padding-right: 19px;
         color: $erste-blue;
         border-color: $erste-blue;
         font-size: 0.9rem;
         margin-right: 0.5rem;
         margin-bottom: 0.35rem;
         margin-top: -0.2rem;
         padding-left: 0;
         .MuiChip-deleteIcon,
         .select-multiple__deleteicon {
            width: 12px;
            height: 100%;
            position: absolute;
            top: 50%;
            right: 5px;
            transform: translateY(-50%);
         }

         .select-multiple__deleteicon {
            margin-top: 2px;
         }
      }
   }
}

.select-multiple--withChips {
   min-height: 45px;
   height: auto;
   .MuiSelect-select.MuiSelect-multiple {
      padding-top: 10px;
      padding-bottom: 3px;
   }
}

.autocomplete {
   display: flex;
   align-items: center;
}

.autocomplete--withInput {
   padding-left: 0;
   padding-right: 0;
   div {
      div {
         padding-left: 10px;
         padding-right: 10px;
      }
   }
}

.autocomplete--withChips {
   min-height: 45px;
   height: auto;
   padding-bottom: 0;
   .MuiSelect-select.MuiSelect-multiple {
      padding-top: 10px;
      padding-bottom: 3px;
   }
}

.header__immosuche {
   .form__field--haslist {
      .input-list {
         margin-top: -20px;
      }
   }
}

.form__field--haslist {
   position: relative;

   .input {
      height: 48px;
   }

   .input-list {
      position: absolute;
      top: 65px;
      left: 0;
      width: 100%;
      background-color:white;
      border-radius: 4px;
      z-index: 2;
      max-height: 480px;
      overflow-y: auto;

      li {
         list-style-type: none;
         margin: 0;
         height: auto;
         border-bottom: 1px solid $midgray;
         font-weight: 400;

         &.input-list__headline {
            font-weight: 600;
            font-size: 0.9rem;
            padding: 5px 10px;
            cursor: initial;
         }

         &:hover {
            background-color: $midgray;
            color: #fff;
            cursor: pointer;
         }
      }
   }
}

.mui-input {
   .MuiOutlinedInput-root {
      margin: 0;
   }

   input {
      padding: 0 !important;
   }

   .MuiAutocomplete-popupIndicator {
      svg {
         width: auto;
         height: 12px;
         padding-right: 5px;
      }
   }

   .MuiOutlinedInput-notchedOutline {
      border: 0px !important;
      border-width: 0px !important;
   }
}

.MuiOutlinedInput-notchedOutline {
   border: 0px !important;
   border-width: 0px !important;
}

select {
   padding: 0.5rem 0.75rem;
   background-image: url(../../../icons/arrow_blue_down.svg);
   background-repeat: no-repeat;
   background-position: calc(100% - 12px) 50%;
   background-size: auto 12px;
   box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
   transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
   appearance: none;
   -moz-appearance: none;
   -webkit-appearance: none;

   // remove arrow
   &::-ms-expand {
      display: none;
   }
}

.form__field--radio {
   display: inline-block;
   position: relative;
   margin-right: 1rem;

   label {
      font-size: 1rem;
   }

   input[type='radio'] {
      width: 1rem;
      height: 1rem;
      margin-right: 14px;

      &:checked:before {
         content: '';
         display: block;
         position: relative;
         top: -6px;
         left: 0px;
         width: 10px;
         height: 10px;
         border-radius: 50%;
         background: #fff;
         border: 10px solid $erste-blue;
         box-sizing: unset;
         margin-left: -1px;
      }

      &:before {
         content: '';
         display: block;
         position: relative;
         top: -6px;
         left: 0px;
         width: 28px;
         height: 28px;
         border-radius: 50%;
         background: white;
         border: 1px solid $midgray;
         box-sizing: unset;
         margin-left: -1px;
      }
   }
}

.form__field--radio-disabled {
   display: inline-block;
   position: relative;
   margin-right: 1rem;

   label {
      font-size: 1rem;
   }

   input[type='radio'] {
      width: 1rem;
      height: 1rem;
      margin-right: 20px;

      &:checked:before {
         content: '';
         display: block;
         position: relative;
         top: -6px;
         left: 0px;
         width: 10px;
         height: 10px;
         border-radius: 50%;
         background: #fff;
         border: 10px solid #707070;
         box-sizing: unset;
         margin-left: -1px;
      }

      &:before {
         content: '';
         display: block;
         position: relative;
         top: -6px;
         left: 0px;
         width: 28px;
         height: 28px;
         border-radius: 50%;
         background: white;
         border: 1px solid $midgray;
         box-sizing: unset;
         margin-left: -1px;
      }
   }
}

#PageDialogEmpfehlung {
   .loading-info {
      margin-top: -20px;
   }
}

.form__field--checkbox {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: flex-start;
	align-items: center;

	input[type='checkbox'] {
		margin-right: 1rem;
		border: 0px;
		outline: none;
		width: 1.5rem;
		height: 1.5rem;
		background-color: white;
		appearance: inherit;
		cursor: pointer;

		&:checked {
				background-color: $erste-blue;
				background-image: url(../../../icons/checked_white.svg);
				background-repeat: no-repeat;
				background-position: center;
				background-size: 80%;
				border: 1px solid $erste-blue !important; 
		}
	}

	&.form__field--checkbox--bordered {
		width: auto;
		
		label {
			font-size: 1rem;
		}
		
		input[type='checkbox'] {
			border-radius: 50%;
			width: 20px;
			height: 20px;
			padding: 0;
			margin-right: 0;
			
			&:checked {
				background-size: 10px;	
			}
		}

	}
   &.form__field--checkbox-filter {
      border: 2px solid $deactiv !important;
      input[type=checkbox] {
         display: none;
      }
      input[type=checkbox]:checked {
         display: block;
      }
      label {
         cursor: pointer;
      }
   }
   &.form__field--checkbox-filter:has(input[type=checkbox]:checked) {
      border: 2px solid $erste-blue !important;
      background-color: rgba(40, 112, 237, 0.08);      ;
   }
}

.form__field--checkbox.checkbox--white {
	input[type='checkbox'] {
		&:checked {
				background-color: #fff;
				background-image: url(../../../icons/checked_blue.svg);
				background-repeat: no-repeat;
				background-position: center;
				background-size: 80%;
				border: 1px solid #fff !important; 
		}
	}
}

.select:focus-visible,
textarea:focus-visible,
.dropdownContainer:focus-visible {
   outline: none;
}

textarea {
   width: 100%;
   height: 160px;
   background-color: white;
   border-radius: 4px;
   padding: 10px;
   font-size: 1rem;
}

.form__satisfactionscale {
   display: flex;
   align-items: center;
   justify-content: space-between;

   > div {
      flex: 0 0 calc(25% - 1rem);
      width: calc(25% - 1rem);
   }
}

.form__field__passwordbutton {
   position: absolute;
   bottom: 10px;
   right: 10px;
   cursor: pointer;
   svg {
      height: 20px;
      width: auto;
   }
}

@media all and (min-width: $mobile_break) {
   .form__row {
      &.form__row--double {
         .form__field {
            margin-bottom: 0;
            flex: 0 0 48%;
            width: 48%;
            .form__field {
               width: 100%;
            }
         }
      }
   }
}

input[type='time']:before {
   content: 'HH:MM';
   color: $darkgray;
   position: absolute;
   background: white;
   width: 70px;
}

input[type='time']:focus:before {
   width: 0;
   content: '';
}

input[type='time'] {
   display: inline-block;
   position: relative;
}

input[type='time']::-webkit-calendar-picker-indicator {
   background: transparent;
   bottom: 0;
   color: transparent;
   cursor: pointer;
   height: auto;
   left: 0;
   position: absolute;
   right: 0;
   top: 0;
   width: auto;
}

input[type='date']:before {
   content: 'TT.MM.JJJJ';
   color: $darkgray;
   position: absolute;
   background: white;
   width: 70px;
}

input[type='date']:focus:before {
   width: 0;
   content: '';
}

input[type='date'] {
   display: inline-block;
   position: relative;
}

input[type='date']::-webkit-calendar-picker-indicator {
   background: transparent;
   bottom: 0;
   color: transparent;
   cursor: pointer;
   height: auto;
   left: 0;
   position: absolute;
   right: 0;
   top: 0;
   width: auto;
}

// PROFILE FORM
.profileForm {
   .form__title {
      font-size: 1.5rem;
      margin-bottom: 2rem;
   }
   .elements3cols {
      .elements3cols__col {
         span {
            font-size: 0.9rem;
         }
         p {
            font-size: 1.3rem;
            font-weight: 600;
            margin-bottom: 1.5rem;
            overflow-wrap: break-word;
         }
      }
   }
}

.profileEmailSettingsForm {
   border: 1px solid $dropdownborder;
   background-color: $cardlightgray;
   padding: 1rem;
   border-radius: 4px;
   margin-top: 1rem;

   > p {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      line-height: 20px;
   }

   svg {
      width: 20px;
      min-width: fit-content;
      height: 20px;
   }

   label {
      font-weight: bold;
   }

   .buttons--disabled {
      button {
         cursor: not-allowed;

         &.button--blue:hover {
            background-color: $erste-blue;
         }
      }
   }

   .profileEmailSettingsForm__fields {
      position: relative;
      background-color: $cardmidgray;
      border: 1px solid $dropdownborder;
      border-radius: 7px;
      padding: 0;
      margin-bottom: 2rem;
      overflow: hidden;

      p {
         margin: 0;
      }
   }

   .elements2cols {
      border-bottom: 1px solid $dropdownborder;
      padding: 1rem;

      &:last-child {
         border: 0px;
      }
   }

   .form__field--radio {
      label::before {
         content: "";
         width: 20px;
         height: 20px;
         border: 1px solid #99ACC0;
         display: block;
         border-radius: 100%;
         position: absolute;
         margin-left: -2.1rem;
         margin-top: -0.2rem;
      }

      input[type="radio"]:checked + label::before {
         border: 4px solid $erste-blue;
         background-color: white;
     }

      input {
         width: 20px;
         z-index: 2;
         position: relative;
         opacity: 0;
         cursor: pointer;

      }
      
      input::before {
         background-color: $erste-blue;
         width: 22px;
         height: 22px;
         top: -2px;
         border: 1px solid $erste-blue;
      }

      input:checked:before {
         background-color: $verylightgray;
         width: 10px;
         height: 10px;
         border-width: 6px;
         top: -2px;
      }
   }
   
   .form__field--checkbox {
      margin-right: 1rem;
      
      input {
         margin-right: 0.5rem;
         border: 1px solid $erste-blue;

         &:not(:checked) {
            background-color: $verylightgray;
         }
      }
   }

   .elements2cols__col {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;

      &:first-child {
         p {
            padding-bottom: 0.5rem;
         }
      }

      .form__row {
         margin: 0;   
      }

      .form__field {
         margin-top: 0.5rem;
         margin-bottom: 0.5rem;
         
         label {
            font-size: 0.85rem;
         }
      }

      &.form--error {
         .form__field--checkbox input {
            border-color: $red;
         }
      }
   }

   .profileEmailSettingsForm__fields--disabled {
      display: none;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0.5;
      background-color: $lightgray;
      z-index: 999;
      cursor: not-allowed;
   }

   .form--disabled {
      color: $gray;

      .profileEmailSettingsForm__fields--disabled { 
         display: inline-block;
      }

      .form__field--checkbox, .form__field--radio {
         input, input:not(:checked), input::before {
            background-color: $midgray !important;
            border-color: $midgray !important;
         }
      }
   }

}

.immosuche {
   display: none;
}

.immosearch-form{
   display: flex;
   flex-wrap: wrap;
   flex-direction: row;
   justify-content: space-between;
   align-items: stretch;
   .immosearch-form__col {
      width: 100%;
   }
} 

.slider-wrapper {
   width: 100%;
   margin-top: 0.5rem;
   div {
      display: flex;
      align-items: center;
      justify-content: space-between;
   }

   .MuiSlider-thumb {
      width: 20px;
      height: 20px;
      border: 2px solid $erste-blue;
      background-color: white;
   }

   .MuiSlider-rail {
      height: 10px;
   }

   .MuiSlider-track {
      height: 10px;
      background-color: $erste-blue;
      border: none;
   }

   .MuiSlider-mark {
      height: 10px;
      width: 10px;
      border-radius: 100%;
      background-color: white;
      opacity: 1;
   }

   .MuiSlider-markActive {
      background-color: $erste-blue;
   }

   .MuiSlider-mark:nth-last-of-type(2) {
      display: none;
   }
}

@media all and (min-width: $mobile_break) {
   .form__field--slider-container {
      margin-top: 1rem;
      display: flex;
      flex-direction: row;
      gap: 1rem;
      .slider-wrapper {
         margin-top: 0rem;
         width: 70%;
         div {
            display: flex;
            align-items: center;
            justify-content: space-between;
         }
      }

      .input-wrapper {
         width: 30%;
      }
   }
   .immosearch-form {
      .immosearch-form__col {
         width: 32%;
      }
   }
   .profileEmailSettingsForm {
      .elements2cols__col {
         &:first-child {
            p {
               padding-bottom: 0;
            }
         }
      }
   }

   .profileForm {
      border: none;
      padding: 0;
   }

   .profileEmailSettingsForm {
      border: none;
      padding: 0;
      margin-top: 0;
   }
   .immosuche {
      display: block;
      border-radius: .75rem;
      width: 450px;
      .searchForm {
         border-radius: .75rem;
      }
   }
   .immosuche:has(.searchForm--longversion) {
      width: 100%;
   }
}

@media all and (min-width: $desktop_break) {
   .immosearch-form {
      .immosearch-form__col {
         width: calc((100% - 470px + 1.5rem) / 2);
      }
      .immosearch-form__col:first-child {
         width: calc(450px - 2rem);
      }
   }  
}