@import "../../../../shared/styles/sreal_default/vars/colors.scss";
@import "../../../../shared/styles/sreal_default/vars/breaks.scss";
@import "../../../../shared/styles/sreal_default/sreal_default_styles.scss";

.table-mobile {
  padding-left: 1rem;
  margin-left: 2rem;

  table {
    width: 100%;
    border-collapse: collapse;
    border-bottom: 1px solid $lightgray;
    margin-right: 2rem;

    th, td {
      padding: 1rem;
      position: relative;
    }

    tr:nth-child(even) {
      background-color: white;
    }

    tr:last-child {
     border-bottom: 1px solid $lightgray;	
    }
  }

  .tableButton {
    position: absolute;
    padding: 1rem;
    border: none;
    background-color: #F4F6FA;
    box-shadow: 0 2px 5px rgba(0,0,0,0.3);
    border-radius: 100px;
    height: 50px;
    width: 50px;
    color: transparent;
    overflow: hidden;
    z-index: 99;
    left: 0;
    transform: translateX(-50%);
  }

  .tableHeader {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    span {
      margin-top: 1rem;
    }
  }

  .horizontal-scroll-except-first-column {
    width: 100%;
    overflow: auto;
  }
  
  .horizontal-scroll-except-first-column > table {
    margin-left: 40%;
  }
  
  .horizontal-scroll-except-first-column > table > * > tr > th:first-child,
  .horizontal-scroll-except-first-column > table > * > tr > td:first-child {
    text-align: left;
    position: absolute;
    width: 40%;
    margin-left: -40%;
    border-left: 1px solid $lightgray;
    border-right: 1px solid $lightgray;
    box-shadow: 0 0 6px rgba(0,0,0,0.3);
    clip-path: inset(0px -15px 0px 0px);
    margin-top: -0.5px;
    z-index: 9;
    word-break: break-word;
    hyphens: auto;
  }

  .horizontal-scroll-except-first-column > table > * > tr > th:nth-child(1),
  .horizontal-scroll-except-first-column > table > * > tr > td:nth-child(1) {
    background-color: white;
  }

  .horizontal-scroll-except-first-column > table > * > tr:last-child > td {
    border-bottom: 1px solid $lightgray;
  }

  .horizontal-scroll-except-first-column > table > * > tr > th:nth-last-child(2),
  .horizontal-scroll-except-first-column > table > * > tr > td:nth-last-child(2) {
    border-right: 1px solid $lightgray;
  }
  
  .horizontal-scroll-except-first-column > table > * > tr > th,
  .horizontal-scroll-except-first-column > table > * > tr > td {
    text-align: center;
    height: 95px;
    border-top: 1px solid $lightgray;
    border-right: 1px solid $lightgray;
  }
}

 @media screen and (min-width: 512px) and (max-width: $mobile_break){
  .horizontal-scroll-except-first-column > table > * > tr > th:not(.tableHeader_blank),
  .horizontal-scroll-except-first-column > table > * > tr > td:not(.tableHeader_blank) {
    height: 80px !important;
  }
 }

.table {
  display: flex;
  flex-direction: column;
  border: 1px solid $lightgray;
  border-radius: 4px;

  .tableRowContent {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px solid $lightgray;
    padding: 1rem;

    &:last-child {
      border-bottom: none;
    }
  }

  .tablerow__label {
    color: $erste-darkblue;
    text-align: left;
  }

  .tablerow__label--subheadline{
    color: $erste-darkblue;
    text-align: left;
    font-weight: bold;
  }

  .tablerow__value {
    color: $erste-darkblue;
    text-align: right;
    overflow-wrap: anywhere;
    @include device(mobile) {
      max-width: 50%;
    }
  }
}

.table--comparison {
  display: flex;
  flex-direction: column;
  
  .tableRowContent:nth-child(even) {
    background-color: $lightmidgray;
  }

	.tableRowContent:nth-child(odd) {
    background-color: #fff;
  }
}

.tableRowContent--comparison {
  display: flex;
  padding: 1rem;

	&.tableRowContent--showmorecolumn {
		background-color: transparent !important;

		.tableColumn, .tableColumn:not(:first-child) {
			text-align: right;
		}
	}

  .tableColumn {
    flex: 1;
  }
  .tableColumn:not(:first-child) {
    text-align: right;
    margin-left: 0.5rem;
  }

  .tableColumn.label {
    color: $erste-darkblue;
    font-weight: bold;
  }
}

.value-margin, .icon-margin {
  margin-right: 0.5rem
}

.tableColumn--headline {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  svg {
    align-self: center;
  }
  span {
    max-width: 12rem;
    align-self: center;
    text-align: center;
  }
}

.button--tablecomparison {
  margin-left: auto;
  margin-right: auto;
  display: block;
} 

@media all and (min-width: $mobile_break) {
  .tableRowContent--comparison {
    .tableColumn:not(:first-child) {
      text-align: center;
      margin-left: 0;
    }
  }
}