@import '../../../../shared/styles/sreal_default/vars/colors.scss';

$respondarea-height: 45px;
$respondarea-height-focus: 135px;
$pagedialogheader-height: 65px;
$errorfield-height: 40px;
$errorfield-margin: 1rem;

.chat-wrapper {
  height: calc(100vh - $pagedialogheader-height);
  position: relative;
  padding: 1rem;
  box-sizing: border-box;
  
  .form__fielderror {
    margin-top: $errorfield-margin;
  }
}

.chatmessages {
  height: calc(100vh - 100px - $respondarea-height);
  overflow-y: auto;
  padding-bottom: 1rem;

  .message {
    display: flex;
    flex-direction: column;
    margin-bottom: 0.25rem;

    .message__date {
      display: inline-block;
      width: 100%;
      text-align: center;
      margin: 1rem 0;
      font-size: 0.8rem;
    }

    .message__date--small {
      visibility: hidden;
      font-size: 0.7rem;
      margin-top: 0.15rem;
      color: $gray;
      transition: 0.25s ease all;
    }

    .message__text {
      max-width: 80%;
      padding: 1rem 1.5rem;
      font-size: 0.9rem;
      border: 0.5px solid;
      border-radius: 4px;
      box-sizing: border-box;
    }

    &.message--user {
      .message__text {
        align-self: flex-end;
        background-color: $erste-blue;
        border-color: $erste-blue;
        color: #fff;

        a, a:hover {
          color: #fff;
        }
      }

      .message__date--small {
        align-self: flex-end;
      }
    }

    &.message--agent {
      .message__text {
        align-self: flex-start;
        color: $erste-darkblue;
        border-color: $lightgray;
      }
    }

    &:hover {
      .message__date--small {
        visibility: visible;
      }
    }
  }
}

.message__respondarea {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: calc(100% - 2rem);
  position: absolute;
  bottom: 0;
  padding: 0.5rem 0;
  background-color: white;

  .message__respondarea-inner {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: nowrap;
    width: 100%;
    height: $respondarea-height;
    padding: 0.25rem 0.5rem;
    border-radius: 4px;
    justify-content: space-between;
    background-color: white;
    gap: 0.5rem;

    &.message__respondarea-inner--loading {
      opacity: 0.6;
    }
  }

  textarea,
  textarea:focus {
    height: 100%;
    flex: 1;
    border: 0;
    box-shadow: none;
    background-color: transparent;
    margin: 0;
    resize: none;
    transition: 0.5s ease all;
    resize: none;
  }

  textarea {
    overflow: hidden;
  }

  textarea:focus {
    overflow: auto;
  }

  .image--round {
    width: 32px;
    height: 32px;
    background-color: $erste-blue;
    position: relative;

    svg {
      width: auto;
      height: 50%;
    }
  }

  .message__respondarea--btn {
    cursor: pointer;
  }
}

.textarea-focus {
  .chatmessages {
    height: calc(100vh - 100px - $respondarea-height-focus);
  }
  .message__respondarea {
    .message__respondarea-inner {
      height: $respondarea-height-focus;
    }
  }
}
