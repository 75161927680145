@import '../../../../shared/styles/sreal_default/vars/colors.scss';
@import '../../../../shared/styles/sreal_default/vars/breaks.scss';

@media all and (min-width: $mobile_break) {
    .alle-angebote {
        .searchprofil-withoutimmos-card {
            .topImage_icon {
                svg {
                    scale: 1;
                }
            }
        }
        .topImage_icon {
            svg {
                scale: 2.7;
            }
        }

        .alle-angebote__options {
            display: none;
        } 
    } 
    .header--alle-angebote {
        h1 {
            // margin-top: 1.5rem;
            margin-bottom: 0;
            color: #fff;
        }

        .textlink, a {
            color: #fff;
        }

        label {
            min-width: fit-content;
        }
        .form__field {
            display: flex;
            align-items: center;
            gap: 20px;
            label {
                margin-bottom: 0rem !important;
                color: white !important;
            }
        }
    }
}

